import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Tag } from "element-react";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "reactstrap";
import { computeRepStatement } from "src/utils/templates";
import { getAllExceptions, getAllTripTemplates } from "../../api/trips";
import TripSquare from "../Generic/TripSquare";

const useStyles = makeStyles({
  row: {
    minWidth: 650,
    overflowX: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    padding: "10px",
    backgroundColor: "#eef0f8",
    borderRadius: "8px",
  },
  generalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "start",
    height: "9rem",
    minWidth: "9rem",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "10px",
    marginLeft: "0 !important",
  },
  centeredTripContainer: {
    alignItems: "center",
  },
  rectangleContainer: {
    width: "100%",
    marginBottom: "1rem",
  },
  searchField: {
    width: "100%",
  },
  tripsModel: {
    display: "flex",
    flexDirection: "column",
    height: "40rem",
    padding: "2rem",
    backgroundColor: "#eef0f8",
    borderRadius: "8px",
  },
  centerText: {
    textAlign: "center",
    padding: "10rem 0",
    color: "#777",
  },
  allTripsContainer: {
    overflowY: "auto",
    overflowX: "hidden",
  },
});

export default function StudentTrips({
  stationId,
  trips,
  onTripAdded,
  onTripDeleted,
}) {
  const classes = useStyles();
  const [showTripsPicker, setShowTripsPicker] = useState(false);
  const [allTrips, setAllTrips] = useState([]);
  const [query, setQuery] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    getAllTripTemplates().then((trips) => {
      getAllExceptions().then((exceptions) => {
        setAllTrips([...trips, ...exceptions]);
      });
    });
  }, []);

  const onTripSelected = (trip) => {
    setShowTripsPicker(false);
    setQuery("");
    // Send request bind student to trip
    onTripAdded && onTripAdded(trip);
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ marginBottom: "10px" }}>Trips</h1>
      <div className={classes.row}>
        <Button
          className={[classes.generalContainer, classes.centeredTripContainer]}
          onClick={() => setShowTripsPicker(true)}
        >
          <i className="fa fa-plus fa-2x" />
        </Button>

        {trips.map((trip) => (
          <TripSquare
            trip={trip}
            onDelete={onTripDeleted}
            key={
              trip.repeatMode !== undefined
                ? "template-" + trip.id
                : "trip-" + trip.id
            }
          />
        ))}
      </div>

      <Modal
        className="modal-dialog-centered"
        isOpen={showTripsPicker}
        toggle={() => setShowTripsPicker(!showTripsPicker)}
      >
        <div className={classes.tripsModel}>
          <div>
            <TextField
              label="Filter trips by route name"
              variant="outlined"
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className={classes.searchField}
            />
          </div>
          <div className={classes.allTripsContainer}>
            {allTrips
              .filter(
                (trip) =>
                  trip.route?.stations?.find(
                    (t) => t.stationId === stationId
                  ) &&
                  !trips.find(
                    (t) => t.id === trip.id && t.repeatMode === trip.repeatMode
                  ) &&
                  trip.route?.name?.toLowerCase().includes(query)
              )
              .map((trip) => (
                <Button
                  className={[
                    classes.generalContainer,
                    classes.rectangleContainer,
                  ]}
                  onClick={() => onTripSelected(trip)}
                  key={
                    trip.repeatMode !== undefined
                      ? "template-" + trip.id
                      : "trip-" + trip.id
                  }
                >
                  <h3>{trip.route?.name}</h3>
                  <Tag type={trip.isGoing ? "success" : "warning"}>
                    {trip.isGoing ? (
                      <i
                        style={{ marginRight: "10px" }}
                        className="fas fa-level-up-alt"
                      ></i>
                    ) : (
                      <i
                        style={{ marginRight: "10px" }}
                        className="fas fa-level-down-alt"
                      ></i>
                    )}
                    {trip.isGoing ? t("trip.toSchool") : t("trip.fromSchool")}
                  </Tag>
                  <Tag type={trip.date ? "primary" : "info"}>
                    {trip.date
                      ? moment(trip.date).format("ddd, MMM, hh:mm a")
                      : computeRepStatement(trip, t)}
                  </Tag>
                </Button>
              )) || <h3 className={classes.centerText}>No Trips found</h3>}
          </div>
        </div>
      </Modal>
    </div>
  );
}

import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  Dialog,
  Form,
  Loading,
  Message,
  MessageBox,
  Switch
} from "element-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal
} from "reactstrap";
import { getAllDrivers } from "src/api/drivers";
import {
  addUser,
  deleteUser,
  editUser,
  getAllRoles,
  getAllUsers,
  notifyUser
} from "src/api/users";
import { AuthContext } from "src/firebase/Auth";
import { validateEmail } from "src/utils/helpers";
import UsersTable from "../components/Tables/UsersTable";
import "./Accounts.css";

const Accounts = (props) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [editingUserID, setEditingUserID] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const initialFormState = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    driverId: null,
    driver: null,
    isAdmin: false,
    isStaff: false,
    roles: [],
  };
  const [formData, setFormData] = useState(initialFormState);
  const initialWrongState = {
    email: false,
  };
  const [isWrong, setIsWrong] = useState(initialWrongState);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [notificationDialogVisible, setNotificationDialogVisible] =
    useState(false);
  const [notificationForm, setNotificationForm] = useState({
    userID: null,
    title: "",
    message: "",
  });
  const { currentUser } = useContext(AuthContext);

  const handleAddAccount = async () => {
    setLoading(true);
    addUser(formData)
      .then((data) => {
        setUsers([...users, data]);
        setLoading(false);
        handleCloseModal();
        Message.success("User added successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "User couldn't be added.",
          customClass: "message-box-class",
        });
      });
  };

  const showInputError = () => {
    setIsWrong({ email: true });
  };
  const handleEditAccount = async () => {
    setLoading(true);
    editUser(editingUserID, formData)
      .then((res) => {
        setUsers(
          users.map((user) => {
            if (user.id === editingUserID) return res;
            return user;
          })
        );
        setLoading(false);
        handleCloseModal();
        Message.success("User edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "User couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };

  const handleUserDelete = async (userID) => {
    MessageBox.confirm(
      "This will permanently delete the user. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoading(true);
        deleteUser(userID)
          .then((res) => {
            setUsers(users.filter((user) => user.id !== userID));
            Message({
              type: "success",
              message: "User deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "User couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  const handleUserEdit = async (userID) => {
    setEditingUserID(userID);
    const editingUser = users.find((user) => user.id === userID);
    setFormData({
      email: editingUser.email ?? "",
      firstName: editingUser.firstName ?? "",
      lastName: editingUser.lastName ?? "",
      phone: editingUser.phone ?? "",
      roles: editingUser.roles ?? [],
      driverId: editingUser.driverId ?? null,
      driver: editingUser.driver ?? (editingUser.driverId ? driverOptions.find(d => d.id === editingUser.driverId) : null),
      isAdmin: editingUser.isAdmin ?? false,
      isStaff: editingUser.isStaff ?? false,
    });
    setAddVisible(true);
  };

  const handleFormChange = (e) => {
    const eName = e.target.name;
    const isRequired = e.target.required;
    setFormData({ ...formData, [eName]: e.target.value });
    if (isRequired) {
      if (validateEmail(e.target.value)) setIsWrong({ email: false });
    }
  };

  const handleNotifyUser = (userID) => {
    setNotificationForm({ ...notificationForm, userID: userID });
    setNotificationDialogVisible(true);
  };
  const handleCloseNotificationDialog = () => {
    setNotificationDialogVisible(false);
    setNotificationForm({
      userID: null,
      title: "",
      message: "",
    });
  };

  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingUserID(null);
    setAddVisible(false);
  };
  useEffect(() => {
    setLoading(true);
    getAllUsers()
      .then((users) => {
        setUsers(users);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
    getAllDrivers().then((drivers) => {
      setDriverOptions(drivers);
    });
    //get a list of roles
    getAllRoles()
      .then((roles) => {
        if (roles) {
          setRoleOptions(roles);
        }
      })
      .catch(console.log);
  }, [currentUser]);

  return (
    <div>
      <div className="search-and-add">
        <div style={{ width: "25rem", maxWidth: "75%", display: "flex" }}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            {/* TODO: Invoke getAllUsers function with the search query */}
            <Input
              placeholder={t("actions.search")}
              type="text"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
              }}
            />
          </InputGroup>
          <Button
            style={{ margin: "0px 10px" }}
            color="default"
            onClick={() => {
              setLoading(true);
              getAllUsers(searchValue)
                .then((users) => {
                  setUsers(users);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                });
            }}
          >
            {t("actions.search")}
          </Button>

          <Button
            onClick={() => {
              setLoading(true);
              getAllUsers()
                .then((users) => {
                  setUsers(users);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                });
            }}
          >
            <i className="fas fa-redo-alt"></i>
          </Button>
        </div>
        <Button
          color="secondary"
          outline
          onClick={() => {
            setAddVisible(true);
          }}
        >
          {t("actions.add")}
          <i className="fas fa-user-plus"></i>
        </Button>
      </div>

      <Modal
        onClosed={handleCloseModal}
        className="modal-dialog-centered"
        isOpen={addVisible}
        toggle={() => {
          setAddVisible(!addVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editingUserID ? t("actions.edit") : t("actions.add")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="add-a-new-account">
          <TextField
            required
            error={isWrong.email}
            label={t("userData.email")}
            helperText={isWrong.email ? "Invalid email." : ""}
            variant="outlined"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleFormChange}
          />

          <div
            className="show-more-form"
            onClick={() => {
              setShowMoreInfo(!showMoreInfo);
            }}
          >
            <p>
              {showMoreInfo ? t("userData.lessInfo") : t("userData.moreInfo")}{" "}
              {showMoreInfo ? (
                <i className="fas fa-chevron-circle-up"></i>
              ) : (
                <i className="fas fa-chevron-circle-down"></i>
              )}
            </p>
          </div>

          {showMoreInfo && (
            <div className="more-form-data">
              <TextField
                label={t("userData.firstName")}
                variant="outlined"
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleFormChange}
              />

              <TextField
                label={t("userData.lastName")}
                variant="outlined"
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleFormChange}
              />

              <TextField
                label={t("userData.phone")}
                variant="outlined"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
              />

              <Switch
                value={formData.isAdmin}
                width={90}
                onValue={true}
                offValue={false}
                onChange={(val) =>
                  setFormData({
                    ...formData,
                    isAdmin: val,
                    isStaff: val,
                  })
                }
              ></Switch>

              <div className="roles-select">
                <Autocomplete
                  value={formData.driver}
                  onChange={(e, value, reason) => {
                    setFormData({
                      ...formData,
                      driverId: value?.id || null,
                      driver: value,
                    });
                  }}
                  id="driver-dropdown"
                  options={driverOptions
                    .filter(
                      (driver) =>
                        users.every(user => user.driverId !== driver.id) ||
                        formData.driverId === driver.id
                    )}
                    getOptionLabel={(option) => option.name || '-'}
                    getOptionSelected={(option, value) => option.id === value.id}
  
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Driver" />
                  )}
                />
              </div>

              <div className="roles-select">
                <Autocomplete
                  value={formData.roles}
                  onChange={(e, values) => {
                    setFormData({
                      ...formData,
                      roles: values,
                    });
                  }}
                  multiple
                  id="roles-dropdown"
                  options={roleOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Roles" />
                  )}
                />
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={handleCloseModal}>
            {t("actions.cancel")}
          </Button>

          {editingUserID ? (
            <Button
              color={validateEmail(formData.email) ? "success" : "secondary"}
              onClick={
                validateEmail(formData.email)
                  ? handleEditAccount
                  : showInputError
              }
            >
              {t("actions.ok")}
            </Button>
          ) : (
            <Button
              color={validateEmail(formData.email) ? "success" : "secondary"}
              onClick={
                validateEmail(formData.email)
                  ? handleAddAccount
                  : showInputError
              }
            >
              {t("actions.add")}
            </Button>
          )}
        </div>
      </Modal>
      <Dialog
        title={t("actions.notify") + " " + t("userData.message")}
        visible={notificationDialogVisible}
        onCancel={handleCloseNotificationDialog}
      >
        <Dialog.Body>
          <Form model={notificationForm}>
            <Form.Item label="Title" labelWidth="120">
              <Input
                value={notificationForm.title}
                onChange={(e) =>
                  setNotificationForm({
                    ...notificationForm,
                    title: e.target.value,
                  })
                }
              ></Input>
            </Form.Item>
            <Form.Item label="Message" labelWidth="120">
              <Input
                type="textarea"
                height="20"
                value={notificationForm.message}
                onChange={(e) =>
                  setNotificationForm({
                    ...notificationForm,
                    message: e.target.value,
                  })
                }
              ></Input>
            </Form.Item>
          </Form>
        </Dialog.Body>

        <Dialog.Footer className="dialog-footer">
          <Button onClick={handleCloseNotificationDialog}>
            {t("actions.cancel")}
          </Button>
          <Button
            disabled={
              notificationForm.title === "" || notificationForm.message === ""
            }
            color="success"
            onClick={() => {
              setLoading(true);
              notifyUser(notificationForm)
                .then((res) => {
                  Message.success({
                    message: "Notification sent successfully",
                    customClass: "message-box-class",
                  });
                })
                .catch((err) => {
                  const errorMessage =
                    err.response.data.message === "not registered"
                      ? "This user is not registered with any mobile device"
                      : "Notification couldn't be sent";
                  Message.error({
                    message: errorMessage,
                    customClass: "message-box-class",
                  });
                })
                .finally(() => {
                  setLoading(false);
                  handleCloseNotificationDialog();
                });
            }}
          >
            {t("actions.send")}
          </Button>
        </Dialog.Footer>
      </Dialog>

      <div className="list-of-accounts">
        <Loading loading={loading}>
          <UsersTable
            users={users}
            onUserDelete={handleUserDelete}
            onUserEdit={handleUserEdit}
            onUserNotify={handleNotifyUser}
          />
        </Loading>
      </div>
    </div>
  );
};

export default Accounts;

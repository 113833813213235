import axios from "./index";

export const getAllStations = async () => {
  return (await axios.get("/api/station")).data;
};
export const addStation = async (formData) => {
  return (await axios.post("/api/station", formData)).data;
};

export const getStation = async (id) => {
  return (await axios.post(`/api/station/${id}`)).data;
};

export const updateStation = async (id, formData) => {
  return (await axios.patch(`/api/station/${id}`, formData)).data;
};

export const deleteStation = async (id) => {
  return (await axios.delete(`/api/station/${id}`)).data;
};

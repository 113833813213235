import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import { Loading, i18n } from "element-react";
import { AuthProvider } from "./firebase/Auth";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/schools.scss"; // Dev
// import "./assets/css/schools.min.css"; // Prod
//for element react
import "element-theme-default";

//for localization
import "./i18next";

import elementEn from "element-react/src/locale/lang/en";
import elementAr from "./elAr";

import { loadState, saveState } from "./store/localStorage/localStorage";
import usersReducer from "./store/reducers/users";

import App from "./App";

//For redux
const rootReducer = combineReducers({
  users: usersReducer,
});
const persistedState = loadState();
const store = createStore(rootReducer, persistedState);
store.subscribe(() => {
  saveState(store.getState());
});

i18n.use(elementEn);
i18n.use(elementAr);

ReactDOM.render(
  <Suspense
    fallback={
      <div className="center-div">
        <Loading />
      </div>
    }
  >
    <AuthProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthProvider>
  </Suspense>,
  document.getElementById("root")
);

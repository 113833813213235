import { TextField } from "@material-ui/core";
import { Loading, Message, MessageBox } from "element-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "reactstrap";
import { addBus, deleteBus, getAllBuses, updateBus } from "src/api/buses";
import BusesTable from "src/components/Tables/BusesTable";
import { AuthContext } from "src/firebase/Auth";

export const Buses = () => {
  const initialFormState = {
    name: "",
    plate: "",
  };
  const initialWrongState = {
    name: false,
    plate: false,
  };

  const { t } = useTranslation();
  const [buses, setBuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [editingBusID, setEditingBusID] = useState(null);
  const [formData, setFormData] = useState(initialFormState);
  const [isWrong, setIsWrong] = useState(initialWrongState);
  const { currentUser } = useContext(AuthContext);

  const handleAddBus = async (invite) => {
    setLoading(true);
    addBus(formData)
      .then((data) => {
        setBuses([...buses, data]);
        setLoading(false);
        handleCloseModal();
        Message.success("Bus added successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Bus couldn't be added.",
          customClass: "message-box-class",
        });
      });
  };
  const handleEditBus = async () => {
    setLoading(true);
    updateBus(editingBusID, formData)
      .then((res) => {
        console.log(res);
        setBuses(
          buses.map((bus) => {
            if (bus.id === editingBusID) return res;
            return bus;
          })
        );
        setLoading(false);
        handleCloseModal();
        Message.success("Bus edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Bus couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };
  const showInputError = async () => {
    setIsWrong({
      name: formData.name === initialFormState.name,
      plate: formData.plate === initialFormState.plate,
    });
  };
  const validateInput = () => {
    return (
      formData.name !== initialFormState.name &&
      formData.plate !== initialFormState.plate
    );
  };
  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingBusID(null);
    setAddVisible(false);
  };
  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });
    if (e.target.required) {
      if (e.target.value !== initialFormState[eName])
        setIsWrong({ ...isWrong, [eName]: false });
    }
  };
  const handleBusDelete = async (busID) => {
    MessageBox.confirm(
      "This will permanently delete the bus. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoading(true);
        deleteBus(busID)
          .then((res) => {
            setBuses(buses.filter((bus) => bus.id !== busID));
            Message({
              type: "success",
              message: "Bus deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Bus couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };
  const handleBusEdit = async (busID) => {
    setEditingBusID(busID);
    const editingBus = buses.find((bus) => bus.id === busID);
    setFormData({
      name: editingBus.name ?? "",
      plate: editingBus.plate ?? "",
    });
    setAddVisible(true);
  };
  useEffect(() => {
    setLoading(true);
    getAllBuses()
      .then((buses) => {
        setBuses(buses);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [currentUser]);
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="default"
          onClick={() => {
            setAddVisible(true);
          }}
        >
          {t("actions.add")}
          <i className="fas fa-plus" style={{ marginLeft: "10px" }}></i>
        </Button>
      </div>
      <Modal
        onClosed={handleCloseModal}
        className="modal-dialog-centered"
        isOpen={addVisible}
        toggle={() => {
          setAddVisible(!addVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editingBusID ? t("actions.edit") : t("actions.add")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div style={{ padding: "0px 20px" }}>
          <TextField
            required
            error={isWrong.name}
            label={t("userData.name")}
            helperText={isWrong.name ? "Name is required." : ""}
            variant="outlined"
            type="name"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
            style={{ marginBottom: "10px" }}
          />
          <TextField
            required
            error={isWrong.plate}
            label={t("userData.plate")}
            helperText={isWrong.plate ? "Plate is required." : ""}
            variant="outlined"
            type="plate"
            name="plate"
            value={formData.plate}
            onChange={handleFormChange}
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={handleCloseModal}>
            {t("actions.cancel")}
          </Button>

          {editingBusID ? (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleEditBus : showInputError}
            >
              {t("actions.ok")}
            </Button>
          ) : (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleAddBus : showInputError}
            >
              {t("actions.add")}
            </Button>
          )}
        </div>
      </Modal>
      <div>
        <Loading loading={loading}>
          <BusesTable
            buses={buses}
            onBusDelete={handleBusDelete}
            onBusEdit={handleBusEdit}
          />
        </Loading>
      </div>
    </div>
  );
};

import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Message } from "element-react";
import * as moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Input, Modal, Row } from "reactstrap";
import { getAllStations } from "src/api/stations";
import {
  addStudentTrip,
  getStudentTripTemplates,
  removeStudentTrip
} from "src/api/students";
import StudentTrips from "src/components/Tables/StudentTrips";
import StudentUsers from "src/components/Tables/StudentUsers";
import {
  addStudentUser,
  getStudentUsers,
  removeStudentUser
} from "../api/students";
import { getAllExceptions, getAllTripTemplates } from "../api/trips";
import { getAllUsers } from "../api/users";
import useWindowDimensions from "../components/Hooks/useWindowDimensions";
import { AuthContext } from "../firebase/Auth";

import "./Student.css";

export default ({
  selectedStudent,
  onDeletePhoto,
  onSubmitPhoto,
  gradeOptions,
  cardOptions,
  onStudentEdit,
}) => {
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation();
  const inputFile = useRef();
  const { currentUser } = useContext(AuthContext);
  const [trips, setTrips] = useState([]);
  const [users, setUsers] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allTrips, setAllTrips] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [imageEditing, setImageEditing] = useState(null);
  const [addImageVisible, setAddImageVisible] = useState(false);
  const [studentID, setStudentID] = useState("");
  const classes = useStyles();

  const initialFormState = {
    name: "",
    email: "",
    phone: "",
    gradeId: "",
    stationId: "",
    birthDate: null,
    cardCode: null,
  };
  const [formData, setFormData] = useState(initialFormState);

  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });
  };

  const handleUserAdded = (user) => {
    setUsers([...users, user]);
    addStudentUser(selectedStudent.id, { userId: user.id });
  };

  const handleUserRemoved = (user) => {
    setUsers(users.filter((u) => u.id !== user.id));
    removeStudentUser(selectedStudent.id, user.id);
  };

  const handleTripAdded = (trip) => {
    setTrips([...trips, trip]);
    if (trip.repeatMode !== undefined) {
      addStudentTrip(selectedStudent.id, {
        tripTemplateId: trip.id,
      });
    } else {
      addStudentTrip(selectedStudent.id, {
        tripId: trip.id,
      });
    }
  };

  const handleTripRemoved = (trip) => {
    setTrips(
      trips.filter(
        (t) => !(t.id === trip.id && t.repeatMode === trip.repeatMode)
      )
    );
    if (trip.repeatMode !== undefined) {
      removeStudentTrip(selectedStudent.id, "template", trip.id);
    } else {
      removeStudentTrip(selectedStudent.id, "trip", trip.id);
    }
  };

  const handleCloseModal = async () => {
    setImagePreview(null);
    setImageEditing(null);
    setSelectedPhoto(null);
    setAddImageVisible(false);
  };
  const onFileUpload = () => {
    inputFile.current.click();
  };
  useEffect(() => {
    setEditMode(false);
    if (selectedStudent) {
      getStudentUsers(selectedStudent.id).then((users) => {
        setUsers(users);
      });
      getStudentTripTemplates(selectedStudent.id).then((data) => {
        setTrips(data);
      });
    } else {
      setTrips([]);
    }
  }, [selectedStudent]);

  useEffect(() => {
    getAllTripTemplates().then((trips) => {
      getAllExceptions().then((exceptions) => {
        setAllTrips([...trips, ...exceptions]);
      });
    });

    getAllUsers().then((users) => {
      setAllUsers(users);
    });

    //get all stations
    getAllStations()
      .then((stations) => {
        setStationOptions(stations);
      })
      .catch(console.log);
  }, []);
  return (
    <div
      style={{
        height: `${width > 768 ? height - 156 : height - 168}px`,
      }}
      className="student-details"
    >
      {selectedStudent ? (
        <div className="student-details-content">
          <div
            className="student-info-block"
            id={editMode ? "edit-mode-info" : ""}
          >
            {currentUser.perms.includes("studentEdit") && editMode ? (
              <div>
                <Button
                  className="edit-student-absolute-button"
                  onClick={() => {
                    onStudentEdit(selectedStudent.id, formData);
                    setEditMode(false);
                  }}
                  color="success"
                >{`${t("actions.save")}`}</Button>
                <Button
                  className="delete-student-absolute-button"
                  onClick={() => {
                    setEditMode(false);
                  }}
                >{`${t("actions.cancel")}`}</Button>
              </div>
            ) : (
              <Button
                className="edit-student-absolute-button"
                onClick={() => {
                  setFormData({
                    name: selectedStudent.name ?? "",
                    email: selectedStudent.email ?? "",
                    phone: selectedStudent.phone ?? "",
                    gradeId: selectedStudent.gradeId ?? "",
                    stationId: selectedStudent.stationId ?? "",
                    cardCode: selectedStudent.cardCode || null,
                    birthDate: selectedStudent.birthDate
                      ? moment(selectedStudent.birthDate).valueOf()
                      : null,
                  });
                  setEditMode(true);
                }}
                color="default"
              >{`${t("actions.edit")}`}</Button>
            )}

            <Container>
              <Row>
                <Col lg={6}>
                  <label>{t("userData.name")}</label>
                  {editMode ? (
                    <Input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleFormChange}
                    />
                  ) : (
                    <p>{selectedStudent.name}</p>
                  )}

                  <label>{t("userData.email")}</label>
                  {editMode ? (
                    <Input
                      type="email"
                      name="email"
                      placeholder="user@email.com"
                      value={formData.email}
                      onChange={handleFormChange}
                    />
                  ) : (
                    <p>{selectedStudent.email}</p>
                  )}
                  <label>{t("userData.phone")}</label>
                  {editMode ? (
                    <Input
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleFormChange}
                    />
                  ) : (
                    <p>{selectedStudent.phone}</p>
                  )}

                  {editMode ? (
                    <form className={classes.container} noValidate>
                      <TextField
                        label={t("userData.birthDate")}
                        type="date"
                        value={moment(formData.birthDate).format("yyyy-MM-DD")}
                        onChange={(e) => {
                          console.log(e.target.value);
                          setFormData({
                            ...formData,
                            birthDate: e.target.value
                              ? moment(e.target.value).valueOf()
                              : null,
                          });
                        }}
                        className={classes.textFieldDateTime}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </form>
                  ) : (
                    <div>
                      {" "}
                      <label>{t("userData.birthDate")}</label>
                      <p>
                        {selectedStudent.birthDate
                          ? moment(selectedStudent.birthDate).format(
                              "YYYY-MMM-DD"
                            )
                          : "-"}
                      </p>
                    </div>
                  )}
                </Col>
                <Col lg={6}>
                  <label>{t("userData.image")}</label>
                  {!selectedStudent.image ? (
                    currentUser.perms.includes("studentEdit") && editMode ? (
                      <Button
                        style={{
                          marginBottom: "20px",
                          display: "block",
                        }}
                        color="success"
                        size="sm"
                        onClick={() => {
                          setAddImageVisible(true);
                          setStudentID(selectedStudent.id);
                        }}
                      >
                        {t("actions.add") + " " + t("userData.image")}
                      </Button>
                    ) : (
                      <img
                        style={{
                          width: "100px",
                          height: "100px",
                          marginBottom: "20px",
                          border: "solid 1px grey",
                        }}
                        src={require("../assets/img/emptyProfile.jpg")}
                        alt="not found"
                      />
                    )
                  ) : (
                    <div
                      style={{
                        position: "relative",
                        marginBottom: "20px",
                        width: "100px",
                        height: "100px",
                        border: "solid 2px black",
                      }}
                    >
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={
                          `${selectedStudent.image}`.startsWith("http://") ||
                          `${selectedStudent.image}`.startsWith("https://")
                            ? selectedStudent.image
                            : `${process.env.REACT_APP_BASE_URL}${selectedStudent.image}`
                        }
                        alt="not found"
                      />

                      {currentUser.perms.includes("studentEdit") && editMode && (
                        <div>
                          <Button
                            color="secondary"
                            size="sm"
                            style={{
                              position: "absolute",
                              bottom: "-5px",
                              right: "-35px",
                            }}
                            onClick={() => {
                              setAddImageVisible(true);
                              setStudentID(selectedStudent.id);
                              setImageEditing(selectedStudent.image);
                            }}
                          >
                            <i className="fas fa-user-edit"></i>
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            style={{
                              position: "absolute",
                              bottom: "-5px",
                              left: "-30px",
                            }}
                            onClick={() => onDeletePhoto(selectedStudent.id)}
                          >
                            <i className="far fa-trash-alt"></i>
                          </Button>
                        </div>
                      )}
                    </div>
                  )}

                  {!editMode ? (
                    <div>
                      <label>{t("userData.grade")}</label>
                      <p>{selectedStudent.grade?.name ?? "-"}</p>
                    </div>
                  ) : (
                    <FormControl className={classes.formControl}>
                      <InputLabel>{t("userData.grade")}</InputLabel>
                      <Select
                        value={formData.gradeId}
                        onChange={(e) => {
                          setFormData({ ...formData, gradeId: e.target.value });
                        }}
                      >
                        {gradeOptions.map((el) => {
                          return (
                            <MenuItem key={el.id} value={el.id}>
                              {el.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  )}

                  {editMode ? (
                    <Autocomplete
                      disablePortal
                      freeSolo
                      className={classes.formControl}
                      placeholder={t("userData.card")}
                      options={cardOptions}
                      defaultValue={formData.cardCode || ""}
                      inputValue={formData.cardCode || ""}
                      onInputChange={(_, val, reason) => {
                        if (val === "" && reason === "reset") {
                          return;
                        }
                        setFormData({ ...formData, cardCode: val || null });
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="cardCode"
                          style={{ width: 200 }}
                          {...params}
                        />
                      )}
                      getOptionLabel={(option) => option.code || ""}
                    />
                  ) : (
                    <div>
                      <label>{t("userData.card")}</label>
                      <p>{selectedStudent.cardCode ?? "No Card"}</p>
                    </div>
                  )}

                  {editMode ? (
                    <TextField
                      required
                      name="stationId"
                      style={{ width: "200px" }}
                      select
                      variant="outlined"
                      label={t("userData.station")}
                      value={formData.stationId}
                      onChange={handleFormChange}
                    >
                      {stationOptions.map((el) => {
                        return (
                          <MenuItem key={el.id} value={el.id}>
                            {el.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  ) : (
                    <div>
                      <label>{t("userData.station")}</label>
                      <p>{stationOptions.find(s => s.id === selectedStudent.stationId)?.name ?? "-"}</p>
                    </div>
                  )}
                </Col>
              </Row>
              <Modal
                onClosed={handleCloseModal}
                className="modal-dialog-centered"
                isOpen={addImageVisible}
                toggle={() => {
                  setAddImageVisible(!addImageVisible);
                }}
              >
                <div className="file-upload">
                  <input
                    onChange={(event) => {
                      if (event.target.files[0]) {
                        if (!event.target.files[0].type.startsWith("image")) {
                          setImagePreview(null);
                          setSelectedPhoto(null);
                          Message.error({
                            message:
                              "Please enter a valid file. Valid files are images",
                            customClass: "message-box-class",
                          });
                        } else {
                          var reader = new FileReader();

                          reader.readAsDataURL(event.target.files[0]);
                          reader.onloadend = (event) => {
                            setImagePreview(event.target.result);
                          };

                          setSelectedPhoto(event.target.files[0]);
                        }
                      }
                    }}
                    style={{ display: "none" }}
                    type="file"
                    ref={inputFile}
                  ></input>
                  <div
                    style={{
                      padding: "20px",
                    }}
                  >
                    <Button
                      style={{
                        height: "50px",
                        display: "block",
                        margin: "auto",
                      }}
                      color="secondary"
                      onClick={onFileUpload}
                    >
                      <i
                        style={{ marginRight: "10px" }}
                        className="fas fa-upload"
                      ></i>
                      {t("userData.image")}
                    </Button>
                    {imagePreview || imageEditing ? (
                      <div style={{ width: "100%" }}>
                        <img
                          src={imagePreview ?? imageEditing}
                          alt="not found"
                          style={{
                            maxWidth: "200px",
                            maxHeight: "200px",
                            margin: "20px auto",
                            display: "block",
                          }}
                        />
                        {imagePreview && (
                          <Button
                            color="success"
                            onClick={() =>
                              onSubmitPhoto(
                                selectedPhoto,
                                studentID,
                                handleCloseModal
                              )
                            }
                            style={{
                              margin: "auto",
                              display: "block",
                            }}
                          >
                            {t("actions.ok")}
                          </Button>
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Modal>
            </Container>
          </div>

          <StudentTrips
            stationId={selectedStudent.stationId}
            trips={trips}
            allTrips={allTrips}
            onTripAdded={handleTripAdded}
            onTripDeleted={handleTripRemoved}
          />

          <StudentUsers
            users={users}
            allUsers={allUsers}
            onUserAdded={handleUserAdded}
            onUserDeleted={handleUserRemoved}
          />

          {/* <StudentExmasTable exams={selectedStudent?.exams || []} />

          <StudentAssignmentsTable
            assignments={selectedStudent?.assignments || []}
          /> */}
        </div>
      ) : (
        <div className="center-div-relative">No Selected Student</div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "rgb(51,238,151)",
  },
  textFieldDateTime: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
    color: "rgb(51,238,151)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

import { Tag } from "element-react";
import React from "react";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import "./RoutesSidebar.css";

export default ({ routes, onSelectRoute, selectedRoute }) => {
  const { width, height } = useWindowDimensions();
  return (
    <div
      style={{ height: `${width > 768 ? height - 156 : height - 168}px` }}
      className="routes-side-bar"
    >
      {routes &&
        routes.map((route) => {
          return (
            <div
              key={route.id}
              className="route-tile"
              id={
                selectedRoute &&
                (selectedRoute.id === route.id ? "route-tile-selected" : "")
              }
              onClick={() => onSelectRoute(route)}
            >
              <p style={{ textAlign: "center" }}>
                <Tag style={{ width: "100%" }}>
                  <i className="fas fa-route" style={{ marginRight: "20px" }} />
                  <span>{route.name}</span>
                </Tag>
              </p>
            </div>
          );
        })}
    </div>
  );
};

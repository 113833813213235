import React from "react";
import PrivateRoute from "./firebase/PrivateRoute";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import TeacherLayout from "./layouts/Teacher.js";
import InvitationPage from "./pages/InvitationPage.js";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <PrivateRoute
          path="/teacher"
          module="teacher"
          component={TeacherLayout}
        />
        <PrivateRoute path="/admin" module="isStaff" component={AdminLayout} />
        <Route
          path="/invitation"
          render={(props) => <InvitationPage {...props} />}
        />
        <Redirect from="/" to="/admin" />
      </Switch>
    </BrowserRouter>
  );
};

import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Tag } from "element-react";
import React, { useState } from "react";
import { Button, Modal } from "reactstrap";
import UserSquare from "../Generic/UserSquare";

const useStyles = makeStyles({
  row: {
    minWidth: 650,
    overflowX: "auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "center",
    padding: "10px",
    backgroundColor: "#eef0f8",
    borderRadius: "8px",
  },
  generalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "start",
    height: "9rem",
    minWidth: "9rem",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "10px",
    marginLeft: "0 !important",
  },
  centeredUserContainer: {
    alignItems: "center",
  },
  rectangleContainer: {
    width: "100%",
    marginBottom: "1rem",
  },
  searchField: {
    width: "100%",
  },
  usersModel: {
    display: "flex",
    flexDirection: "column",
    height: "40rem",
    padding: "2rem",
    backgroundColor: "#eef0f8",
    borderRadius: "8px",
  },
  centerText: {
    textAlign: "center",
    padding: "10rem 0",
    color: "#777",
  },
  allUsersContainer: {
    overflowY: "auto",
    overflowX: "hidden",
  },
});

export default function StudentUsers({
  allUsers,
  users,
  onUserAdded,
  onUserDeleted,
}) {
  const classes = useStyles();
  const [showUsersPicker, setShowUsersPicker] = useState(false);
  const [query, setQuery] = useState("");

  const onUserSelected = (user) => {
    setShowUsersPicker(false);
    setQuery("");
    // Send request bind student to user
    onUserAdded && onUserAdded(user);
  };

  return (
    <div style={{ padding: "20px" }}>
      <h1 style={{ marginBottom: "10px" }}>Users</h1>
      <div className={classes.row}>
        <Button
          className={[classes.generalContainer, classes.centeredUserContainer]}
          onClick={() => setShowUsersPicker(true)}
        >
          <i className="fa fa-plus fa-2x" />
        </Button>

        {users.map((user) => (
          <UserSquare user={user} onDelete={onUserDeleted} key={user.id} />
        ))}
      </div>

      <Modal
        className="modal-dialog-centered"
        isOpen={showUsersPicker}
        toggle={() => setShowUsersPicker(!showUsersPicker)}
      >
        <div className={classes.usersModel}>
          <div>
            <TextField
              label="Filter users by name"
              variant="outlined"
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className={classes.searchField}
            />
          </div>
          <div className={classes.allUsersContainer}>
            {allUsers
              .filter(
                (user) =>
                  !users.find((u) => u.id === user.id) &&
                  (user.firstName?.toLowerCase().includes(query) ||
                    user.lastName?.toLowerCase().includes(query))
              )
              .map((user) => (
                <Button
                  className={[
                    classes.generalContainer,
                    classes.rectangleContainer,
                  ]}
                  onClick={() => onUserSelected(user)}
                  key={user.id}
                >
                  <h3>{`${user.firstName} ${user.lastName}`.trim()}</h3>
                  <Tag type="success">{user.email}</Tag>
                </Button>
              )) || <h3 className={classes.centerText}>No Users found</h3>}
          </div>
        </div>
      </Modal>
    </div>
  );
}

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import React, { useEffect, useState } from "react";

import { FormControl, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Loading, Message, MessageBox, Switch, Tag } from "element-react";
import * as moment from "moment";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, PopoverBody, UncontrolledPopover } from "reactstrap";
import { getAllBuses } from "src/api/buses";
import { getAllDrivers } from "src/api/drivers";
import { getAllRoutes } from "src/api/routes";
import { deleteTrip, getAllTrips, saveTrip } from "src/api/trips";
import "./Trips.css";

export default () => {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingTripObj, setEditingTripObj] = useState(null);
  const [addVisible, setAddVisible] = useState(false);
  const [routeOptions, setRouteOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [busOptions, setBusOptions] = useState([]);
  // const [onGoingTimeOut, setOnGoingTimeOut] = useState(false);
  const onGoingTimeOut = useRef(false);
  const timeOut = useRef();
  const firstTime = useRef(true);
  const [startDate, setStartDate] = useState(
    moment().format("YYYY-MM-DD").toString()
  );
  const [endDate, setEndDate] = useState(
    moment().add(7, "days").format("YYYY-MM-DD").toString()
  );
  const { t } = useTranslation();
  const classes = useStyles();

  const initialFormState = {
    id: null,
    date: new Date().toISOString(),
    routeId: null,
    busId: null,
    driverId: null,
    isGoing: false,
    templateId: null,
  };
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    const tripsRequest = () => {
      timeOut.current = setTimeout(
        () => {
          setLoading(true);
          getAllTrips(
            new Date(startDate).toISOString(),
            new Date(endDate).toISOString()
          )
            .then((res) => {
              console.log(res);
              setTrips(res);
            })
            .catch(console.log)
            .finally(() => {
              setLoading(false);
              onGoingTimeOut.current = false;
              firstTime.current = false;
            });
        },
        firstTime.current ? 0 : 700
      );
    };
    if (onGoingTimeOut.current) {
      clearTimeout(timeOut.current);
      tripsRequest();
    } else {
      onGoingTimeOut.current = true;
      tripsRequest();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    //get all routes
    getAllRoutes()
      .then((routes) => {
        setRouteOptions(routes);
      })
      .catch(console.log);

    //get all drivers
    getAllDrivers()
      .then((drivers) => {
        setDriverOptions(drivers);
      })
      .catch(console.log);

    //get all buses
    getAllBuses()
      .then((buses) => {
        setBusOptions(buses);
      })
      .catch(console.log);
  }, []);
  const handleDateClick = (args) => {
    console.log(args);
    console.log("DATE PRESSED");
  };

  //handlers
  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingTripObj(null);
    setAddVisible(false);
  };
  const handleEditTrip = () => {
    setLoading(true);
    saveTrip(formData)
      .then((res) => {
        setTrips(
          trips.map((trip) => {
            if (JSON.stringify(trip) === JSON.stringify(editingTripObj))
              return res;
            return trip;
          })
        );
        setLoading(false);
        handleCloseModal();
        Message.success("Trip edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Trip couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };
  const handleAddTrip = () => {
    setLoading(true);
    saveTrip(formData)
      .then((addedTrip) => {
        setTrips([...trips, addedTrip]);
        Message.success({
          message: "Trip Added successfully",
          customClass: "message-box-class",
        });
      })
      .catch((err) => {
        Message.error({
          message: "Trip couldn't be added",
          customClass: "message-box-class",
        });
      })
      .finally(() => {
        handleCloseModal();
        setLoading(false);
      });
  };
  const handleTripDelete = (id) => {
    MessageBox.confirm(t("msg.deleteTrip"), "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    })
      .then(() => {
        setLoading(true);
        deleteTrip(id)
          .then((res) => {
            setTrips(trips.filter((template) => template.id !== id));
            Message({
              type: "success",
              message: "Trip deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Trip couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };
  const handleTripEdit = (tripObj) => {
    setEditingTripObj(tripObj);
    const editingTrip = tripObj;
    setFormData({
      id: editingTrip.id,
      date: editingTrip.date
        ? moment(editingTrip.date).toISOString()
        : null,
      routeId: editingTrip.routeId ?? initialFormState.routeId,
      busId: editingTrip.busId ?? initialFormState.busId,
      driverId: editingTrip.driverId ?? initialFormState.driverId,
      isGoing: editingTrip.isGoing ?? initialFormState.isGoing,
      templateId: editingTrip.templateId ?? initialFormState.templateId,
    });
    setAddVisible(true);
  };
  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });

    if (e.target.name === "routeId") setIsWrong({ ...isWrong, routeId: false });
    if (e.target.name === "busId") setIsWrong({ ...isWrong, busId: false });
    if (e.target.name === "driverId") setIsWrong({ ...isWrong, driverId: false });
    if (e.target.required) {
      if (e.target.value !== initialFormState[eName])
        setIsWrong({ ...isWrong, [eName]: false });
    }
  };

  const initialWrongState = {
    date: false,
    routeId: false,
    driverId: false,
    busId: false,
  };
  const [isWrong, setIsWrong] = useState(initialWrongState);
  const showInputError = async () => {
    setIsWrong({
      date: formData.date === initialFormState.date,
      routeId: formData.routeId === initialFormState.routeId,
      driverId: formData.driverId === initialFormState.driverId,
      busId: formData.busId === initialFormState.busId,
    });
  };

  //validators
  const validateInput = () => {
    return (
      formData.routeId !== "" &&
      formData.driverId !== "" &&
      formData.busId !== "" &&
      formData.date !== ""
    );
  };
  return (
    <>
      <div className="from-to-add-trip">
        <div>
          <form className={classes.container} noValidate>
            <TextField
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              label={t("datepicker.startDate")}
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <form className={classes.container} noValidate>
            <TextField
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              label={t("datepicker.endDate")}
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </div>
        <Button
          onClick={() => setAddVisible(true)}
        >
          <i style={{ marginRight: "10px" }} className="fas fa-bus"></i>
          {t("actions.add")}
        </Button>
      </div>
      <div className="trip-calendar">
        {" "}
        <Loading loading={loading}>
          <FullCalendar
            plugins={[
              timeGridPlugin,
              listPlugin,
              dayGridPlugin,
              interactionPlugin,
            ]}
            initialView="timeGridDay"
            validRange={{
              start: startDate,
              end: endDate,
            }}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,listWeek,timeGridDay", //timeGridWeek
            }}
            events={trips.map((trip) => {
              return {
                title: trip.route.name,
                date: new Date(trip.date),
                id: trip.id || null,
                extendedProps: { ...trip, id: trip.id || null },
                className: "trip-event",
              };
            })}
            slotEventOverlap={false}
            allDaySlot={false}
            dateClick={handleDateClick}
            eventContent={(el) => {
              const trip = el.event.extendedProps;
              const uniqueSelectorID = `PopoverLegacy${trip.route.name.replace(
                /\s/g,
                ""
              )}${moment(trip.date)
                .format("YYYYDDMMhhmm")
                .toString()}${Math.random().toString().replace(".", "")}`;
              return (
                <div>
                  <div id={uniqueSelectorID}>{trip.templateId ? '[T] ' : ''}{trip.route.name}</div>
                  <UncontrolledPopover
                    trigger="legacy"
                    placement="left"
                    target={uniqueSelectorID}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 20px",
                        borderBottom: "solid 1px rgb(205,205,207)",
                      }}
                    >
                      <span>{trip.route.name}</span>
                      <div>
                        {trip.id ? <Button
                          color="success"
                          size="sm"
                          className="edit-event"
                          onClick={() => handleTripEdit(trip)}
                        >
                          <i className="fas fa-edit"></i>
                        </Button> : null}
                        {trip.id ? (
                          <Button
                            color="warning"
                            size="sm"
                            className="delete-event"
                            onClick={() => handleTripDelete(trip.id)}
                          >
                            <i className="fas fa-trash-alt"></i>
                          </Button>
                        ) : null}
                      </div>
                    </div>

                    <PopoverBody>
                      <div>
                        <div className="trip-event-details">
                          <p>
                            <span>{`${t("trip.time")}:`}</span>
                            <Tag type="gray">
                              <i
                                style={{ marginRight: "5px" }}
                                className="far fa-clock"
                              ></i>
                              {moment(trip.date).format("hh:mm a")}
                            </Tag>
                          </p>
                          <p>
                            <span>{`${t("trip.isGoing")}:`}</span>
                            <Tag type={trip.isGoing ? "success" : "warning"}>
                              {trip.isGoing ? (
                                <i
                                  style={{ marginRight: "10px" }}
                                  className="fas fa-level-up-alt"
                                ></i>
                              ) : (
                                <i
                                  style={{ marginRight: "10px" }}
                                  className="fas fa-level-down-alt"
                                ></i>
                              )}
                              {trip.isGoing
                                ? t("trip.toSchool")
                                : t("trip.fromSchool")}
                            </Tag>
                          </p>
                          <p>
                            <span>{`${t("trip.driver")}:`}</span>
                            {trip.driver?.name}
                          </p>
                          <p>
                            <span>{`${t("trip.bus")}:`}</span>
                            <Tag> {trip.bus?.plate}</Tag>
                          </p>
                          <p>
                            <span>{`${t("trip.current_station")}:`}</span>
                            <Tag> {trip.currentStation?.name || '--'}</Tag>
                          </p>
                        </div>
                      </div>
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
              );
            }}
          />
        </Loading>
      </div>
      <Modal
        onClosed={handleCloseModal}
        className="modal-dialog-centered"
        isOpen={addVisible}
        toggle={() => {
          setAddVisible(!addVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editingTripObj ? t("actions.edit") : t("actions.add")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="add-a-new-trip">
          <form className={classes.container} noValidate>
            <TextField
              label={t("trip.date")}
              required
              error={isWrong.date}
              helperText={isWrong.date ? "Name is required." : ""}
              variant="outlined"
              type="datetime-local"
              value={formData.date}
              onChange={handleFormChange}
              name="date"
              className={classes.textFieldDateTime}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
          <FormControl className={classes.formControl}>
            <TextField
              label={t("trip.route")}
              select
              required
              error={isWrong.routeId}
              helperText={isWrong.routeId ? "Route is required." : ""}
              variant="outlined"
              value={formData.routeId}
              onChange={handleFormChange}
              name="routeId"
            >
              {routeOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              label={t("trip.driver")}
              select
              required
              error={isWrong.driverId}
              helperText={isWrong.driverId ? "Driver is required." : ""}
              variant="outlined"
              value={formData.driverId}
              onChange={handleFormChange}
              name="driverId"
            >
              {driverOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>

          <FormControl className={classes.formControl}>
            <TextField
              label={t("trip.bus")}
              select
              required
              error={isWrong.busId}
              helperText={isWrong.busId ? "Bus is required." : ""}
              variant="outlined"
              value={formData.busId}
              onChange={handleFormChange}
              name="busId"
            >
              {busOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {`${el.name} ${el.plate}`}
                  </MenuItem>
                );
              })}
            </TextField>
          </FormControl>

          <div className="select-option">
            <Switch
              style={{ display: "inline-block", marginRight: "20px" }}
              value={formData.isGoing}
              onChange={(value) => {
                setFormData({ ...formData, isGoing: value });
              }}
              offColor="rgb(251,209,97)"
              onColor="rgb(19,206,102)"
              onIconClass="fas fa-level-up-alt"
              offIconClass="fas fa-level-down-alt"
            ></Switch>
            <span>
              {formData.isGoing ? t("trip.toSchool") : t("trip.fromSchool")}
            </span>
          </div>
        </div>

        <div className="modal-footer">
          <Button color="secondary" onClick={handleCloseModal}>
            {t("actions.cancel")}
          </Button>

          {editingTripObj ? (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleEditTrip : showInputError}
            >
              {t("actions.ok")}
            </Button>
          ) : (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleAddTrip : showInputError}
            >
              {t("actions.add")}
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

//styles for material ui

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    color: "rgb(51,238,151)",
  },
  textFieldDateTime: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
    color: "rgb(51,238,151)",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Loading, Message, MessageBox } from "element-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "reactstrap";
import {
  addDriver, deleteDriver, getAllDrivers, updateDriver
} from "src/api/drivers";
import {
  getAvailableCards
} from "src/api/students";

import DriversTable from "src/components/Tables/DriversTable";
import { AuthContext } from "src/firebase/Auth";

export const Drivers = () => {
  const initialFormState = {
    name: "",
    license: "",
    phone: "",
    cardCode: null,
  };
  const initialWrongState = {
    name: false,
    license: false,
    phone: false,
    cardCode: false,
  };

  const { t } = useTranslation();
  const [drivers, setDrivers] = useState([]);
  const [cardOptions, setCardOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [editingDriverID, setEditingDriverID] = useState(null);
  const [formData, setFormData] = useState(initialFormState);
  const [isWrong, setIsWrong] = useState(initialWrongState);
  const { currentUser } = useContext(AuthContext);

  const handleAddDriver = async (invite) => {
    setLoading(true);
    addDriver(formData)
      .then((data) => {
        setDrivers([...drivers, data]);
        setLoading(false);
        handleCloseModal();
        Message.success("Driver added successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Driver couldn't be added.",
          customClass: "message-box-class",
        });
      });
  };
  const handleEditDriver = async () => {
    setLoading(true);
    updateDriver(editingDriverID, formData)
      .then((res) => {
        console.log(res);
        setDrivers(
          drivers.map((driver) => {
            if (driver.id === editingDriverID) return res;
            return driver;
          })
        );
        setLoading(false);
        handleCloseModal();
        Message.success("Driver edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Driver couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };
  const showInputError = async () => {
    setIsWrong({
      name: formData.name === initialFormState.name,
      license: formData.license === initialFormState.name,
    });
  };
  const validateInput = () => {
    return (
      formData.name !== initialFormState.name &&
      formData.license !== initialFormState.license
    );
  };

  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingDriverID(null);
    setAddVisible(false);
  };

  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });
    if (e.target.required) {
      if (e.target.value !== initialFormState[eName])
        setIsWrong({ ...isWrong, [eName]: false });
    }
  };
  const handleDriverDelete = async (driverID) => {
    MessageBox.confirm(
      "This will permanently delete the driver. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoading(true);
        deleteDriver(driverID)
          .then((res) => {
            setDrivers(drivers.filter((driver) => driver.id !== driverID));
            Message({
              type: "success",
              message: "Driver deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Driver couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };
  const handleDriverEdit = async (driverID) => {
    setEditingDriverID(driverID);
    const editingDriver = drivers.find((driver) => driver.id === driverID);
    setFormData({
      name: editingDriver.name ?? "",
      license: editingDriver.license ?? "",
      phone: editingDriver.phone ?? "",
      cardCode: editingDriver.cardCode ?? null,
    });
    setAddVisible(true);
  };
  useEffect(() => {
    setLoading(true);
    getAllDrivers()
      .then((drivers) => {
        setDrivers(drivers);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

      //get available cards
    getAvailableCards()
    .then((res) => {
      setCardOptions(res);
    })
    .catch(console.log);
  }, [currentUser]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="default"
          onClick={() => {
            setAddVisible(true);
          }}
        >
          {t("actions.add")}
          <i className="fas fa-plus" style={{ marginLeft: "10px" }}></i>
        </Button>
      </div>
      <Modal
        onClosed={handleCloseModal}
        className="modal-dialog-centered"
        isOpen={addVisible}
        toggle={() => {
          setAddVisible(!addVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editingDriverID ? t("actions.edit") : t("actions.add")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div style={{ padding: "0px 20px" }}>
          <TextField
            required
            error={isWrong.name}
            label={t("userData.name")}
            helperText={isWrong.name ? "Name is required." : ""}
            variant="outlined"
            type="name"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
            style={{ marginBottom: "10px" }}
          />
          <TextField
            required
            error={isWrong.license}
            label={t("userData.license")}
            helperText={isWrong.license ? "License is required." : ""}
            variant="outlined"
            type="license"
            name="license"
            value={formData.license}
            onChange={handleFormChange}
            style={{ marginBottom: "10px" }}
          />
          <TextField
            required
            error={isWrong.phone}
            label={t("userData.phone")}
            helperText={isWrong.phone ? "Phone is required." : ""}
            variant="outlined"
            type="phone"
            name="phone"
            value={formData.phone}
            onChange={handleFormChange}
            style={{ marginBottom: "10px" }}
          />

          <Autocomplete
            disablePortal
            freeSolo
            options={cardOptions}
            inputValue={formData.cardCode || ""}
            onInputChange={(_, val, reason) => {
              if (val === "" && reason === "reset") {
                return;
              }
              setFormData({ ...formData, cardCode: val || null });
            }}

            sx={{ width: "200px" }}
            renderInput={(params) => (
              <TextField
              name="cardCode"
              variant="outlined"
              label={t("userData.card")}
              style={{ width: "200px" }}
              {...params}
              />
            )}
            getOptionLabel={(option) => option.code || ""}
          />

        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={handleCloseModal}>
            {t("actions.cancel")}
          </Button>

          {editingDriverID ? (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleEditDriver : showInputError}
            >
              {t("actions.ok")}
            </Button>
          ) : (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleAddDriver : showInputError}
            >
              {t("actions.add")}
            </Button>
          )}
        </div>
      </Modal>
      <div>
        <Loading loading={loading}>
          <DriversTable
            drivers={drivers}
            onDriverDelete={handleDriverDelete}
            onDriverEdit={handleDriverEdit}
          />
        </Loading>
      </div>
    </div>
  );
};

import axios from "./index";

export const getAllBuses = async () => {
  return (await axios.get("/api/bus")).data;
};

export const addBus = async (data) => {
  return (await axios.post("/api/bus", data)).data;
};

export const updateBus = async (id, data) => {
  return (await axios.patch(`/api/bus/${id}`, data)).data;
};

export const deleteBus = async (id) => {
  return (await axios.delete(`/api/bus/${id}`)).data;
};

import { Alert, Loading } from "element-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button } from "reactstrap";
import "./InvitationPage.css";

export default ({ history }) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    const userID = history.location.pathname.split("/")[2];
    if (userID) {
      //make the invitation request here
      console.log(userID);
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [history.location.pathname]);

  const validateInput = () => {
    return password.length > 8 && password === confirmPassword;
  };

  if (loading)
    return (
      <div className="center-div">
        <Loading loading={loading} />
      </div>
    );
  return (
    <div className="center-div-relative invitation-page">
      <img src={require("../assets/img/schools.png")} alt="Not found" />
      <Alert
        className="invitation-message"
        title="Account Verified"
        type="success"
        description="Your account has successfully been verified. 
        Please submit your password below."
        showIcon={true}
        closable={false}
      />

      <label htmlFor="password">{t("userData.password")} </label>
      <Input
        type="password"
        name="password"
        value={password}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <label htmlFor="confirmPassword">
        {`${t("actions.confirm")} ${t("userData.password")}`}
      </label>
      <Input
        type="password"
        name="confirmPassword"
        value={confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
        }}
      />

      <Button
        color={validateInput() ? "default" : ""}
        disabled={!validateInput()}
      >
        Submit
      </Button>
    </div>
  );
};

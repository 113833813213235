import { Loading, Message, Radio, Tag } from "element-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Modal, Button } from "reactstrap";
import { updateCurrentUser } from "src/api/users";
import { validateEmail } from "src/utils/helpers";
import "./ProfileModal.css";

export default ({
  handleCloseProfileModal,
  visibleProfileModal,
  toggleProfileModal,
  currentUser,
}) => {
  const { t, i18n } = useTranslation();
  const [user, setUser] = useState(currentUser);
  const [radioValue, setRadioValue] = useState(user.lang);
  const [loading, setLoading] = useState(false);
  const onChangeRadio = (value) => {
    i18n.changeLanguage(value);
    setRadioValue(value);
    setUser({ ...user, lang: value });
  };

  const handleFormChange = (e) => {
    const eName = e.target.name;
    setUser({ ...user, [eName]: e.target.value });
  };

  const handleSaveInfo = () => {
    setLoading(true);
    updateCurrentUser(user)
      .then((res) => {
        setLoading(false);
        Message.success({
          message: "Updated successfully!",
          customClass: "message-box-class",
        });
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: " Couldn't be updated!",
          customClass: "message-box-class",
        });
      });
  };
  return (
    <Modal
      onClosed={handleCloseProfileModal}
      className="modal-dialog-centered"
      isOpen={visibleProfileModal}
      toggle={toggleProfileModal}
      size="lg"
    >
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">
          PROFILE
        </h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={handleCloseProfileModal}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <Loading loading={loading}>
        <div className="profile-content">
          <div className="profile-header">
            <h1 className="picture-initials">
              {user &&
                user.firstName &&
                !user.firstName.isEmpty &&
                user.firstName.charAt(0).toUpperCase()}
              {user &&
                user.lastName &&
                !user.lastName.isEmpty &&
                user.lastName.charAt(0).toUpperCase()}
            </h1>
            <Tag type="gray" className="profile-role-name">
              {user &&
                (user.isAdmin
                  ? t("userData.admin")
                  : user.isStaff
                  ? t("userData.staff")
                  : "")}
            </Tag>
          </div>
          <label htmlFor="email">{t("userData.email")} </label>

          <p className="profile-email">{user && user.email}</p>

          <label htmlFor="firstName">{t("userData.firstName")} </label>
          <Input
            type="text"
            name="firstName"
            value={user.firstName || ""}
            onChange={handleFormChange}
          />
          <label htmlFor="lastName">{t("userData.lastName")} </label>
          <Input
            type="text"
            name="lastName"
            value={user.lastName || ""}
            onChange={handleFormChange}
          />
          <label htmlFor="phone">{t("userData.phone")} </label>
          <Input
            type="tel"
            name="phone"
            value={user.phone || ""}
            onChange={handleFormChange}
          />

          <Radio
            value="en"
            checked={radioValue === "en"}
            onChange={onChangeRadio}
          >
            {t("languages.en")}
          </Radio>
          <Radio
            value="ar"
            checked={radioValue === "ar"}
            onChange={onChangeRadio}
          >
            {t("languages.ar")}
          </Radio>
        </div>
      </Loading>
      <div className="modal-footer">
        <Button
          color={validateEmail(user.email) ? "success" : "secodary"}
          onClick={handleSaveInfo}
          disabled={!validateEmail(user.email)}
        >
          {t("actions.save")}
        </Button>
      </div>
    </Modal>
  );
};

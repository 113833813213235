import { makeStyles } from "@material-ui/core";
import { Tag } from "element-react";
import * as moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { computeRepStatement } from "src/utils/templates";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "start",
    height: "9rem",
    width: "9rem",
    minWidth: "min-content",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "10px",
    marginRight: "1rem",
    position: "relative",

  },
  deleteButton: {
    position: "absolute",
    top: "-8px",
    right: "-20px",
    backgroundColor: "#fff",
    color: "#000",
    borderRadius: "100px",
    border: "1px solid #777",
    height: "2rem",
    width: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  activeDeleteButton: {
    width: "5rem",
  },
});

export default function TripSquare({ trip, onDelete }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [shown, setShown] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const onDeleteClick = () => {
    if (isDelete) {
      onDelete && onDelete(trip);
    } else {
      setIsDelete(true);
      setTimeout(() => {
        setIsDelete(false);
      }, 2000);
    }
  };

  return (
    <div
      className={classes.container}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      {shown && (
        <Button
          color={isDelete && "danger"}
          className={[
            classes.deleteButton,
            isDelete && classes.activeDeleteButton,
          ]}
          onClick={onDeleteClick}
        >
          {isDelete ? t("convo.delete") : <i className="fas fa-trash fa-w-1" style={{
            marginHorizontal: 0,
          }} />}
        </Button>
      )}
      <h3>{trip.route?.name}</h3>
      <Tag type={trip.isGoing ? "success" : "warning"}>
        {trip.isGoing ? (
          <i
            style={{ marginRight: "10px" }}
            className="fas fa-level-up-alt"
          ></i>
        ) : (
          <i
            style={{ marginRight: "10px" }}
            className="fas fa-level-down-alt"
          ></i>
        )}
        {trip.isGoing ? t("trip.toSchool") : t("trip.fromSchool")}
      </Tag>
      <Tag type={trip.date ? "primary" : "info"}>
      {trip.date ? moment(trip.date).format("ddd, MMM, hh:mm a") : computeRepStatement(trip, t)}
        
        </Tag>
    </div>
  );
}

import { Popover, Table } from "element-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import CheckStatus from "../Generic/CheckStatus";
import "./UsersTable.css";

class UsersTable extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.t !== this.props.t) {
      this.setState(
        (prev) => {
          prev.columns.map((e) => {
            e.label = e.translationKey
              ? e.translationKey === " "
                ? " "
                : this.props.t(e.translationKey)
              : this.props.t(`userData.${e.prop}`);
            return e;
          });
          return prev;
        },
        () => {
          this.setState({ tableKey: Math.random() });
        }
      );
    }
  }
  state = {
    tableKey: 0,
    columns: [
      {
        label: this.props.t("userData.email"),
        prop: "email",
        sortable: true,
        width: 300,
      },
      {
        label: this.props.t("userData.firstName"),
        prop: "firstName",
        sortable: true,
      },
      {
        label: this.props.t("userData.lastName"),
        prop: "lastName",
        sortable: true,
      },
      {
        label: this.props.t("userData.phone"),
        prop: "phone",
        width: 143,
      },
      {
        label: this.props.t("userData.admin"),
        prop: "isAdmin",
        translationKey: "userData.admin",
        width: 85,
        align: "center",
        filters: [
          {
            text: this.props.t("userData.admin"),
            value: this.props.t("userData.admin"),
          },
          {
            text: this.props.t("userData.notAdmin"),
            value: this.props.t("userData.notAdmin"),
          },
        ],
        filterMethod(value, row) {
          return row.isAdmin === value;
        },
        render: (data, column) => {
          if (data.isAdmin === "Admin") {
            return <CheckStatus status={true} />;
          } else if (data.isAdmin === "Not Admin") {
            return <CheckStatus status={false} />;
          }
        },
      },
      // {
      //   label: this.props.t("userData.staff"),
      //   prop: "isStaff",
      //   width: 85,
      //   align: "center",
      //   translationKey: "userData.staff",
      //   filters: [
      //     {
      //       text: this.props.t("userData.staff"),
      //       value: this.props.t("userData.staff"),
      //     },
      //     {
      //       text: this.props.t("userData.notStaff"),
      //       value: this.props.t("userData.notStaff"),
      //     },
      //   ],
      //   filterMethod(value, row) {
      //     return row.isStaff === value;
      //   },
      //   render: (data, column) => {
      //     if (data.isStaff === "Staff") {
      //       return <CheckStatus status={true} />;
      //     } else if (data.isStaff === "Not Staff") {
      //       return <CheckStatus status={false} />;
      //     }
      //   },
      // },
      // {
      //   label: this.props.t("userData.status"),
      //   prop: "status",
      //   align: "center",
      //   width: "120",
      //   render: (data, column) => {
      //     if (data.status === "unverified") {
      //       return (
      //         <Tag type="danger">{this.props.t(`status.${data.status}`)}</Tag>
      //       );
      //     } else if (data.status === "verified") {
      //       return (
      //         <Tag type="success">{this.props.t(`status.${data.status}`)}</Tag>
      //       );
      //     } else if (data.status === "pending") {
      //       return (
      //         <Tag type="primary">{this.props.t(`status.${data.status}`)}</Tag>
      //       );
      //     }
      //   },
      // },
      {
        label: " ",
        width: "70",
        align: "center",
        translationKey: " ",
        render: (data) => {
          return (
            // TODO: ERROR when state changed
            <Popover
              placement="right"
              trigger="click"
              style={{
                padding: 0,
                minWidth: "90px",
                width: "90px",
              }}
              content={
                <div className="table-dropdown-menu">
                  <span
                    type="text"
                    onClick={() => this.props.onUserEdit(data.id)}
                  >
                    <i className="el-icon-edit"></i>{" "}
                    {this.props.t("actions.edit")}
                  </span>
                  <span
                    type="text"
                    onClick={() => this.props.onUserDelete(data.id)}
                  >
                    <i className="el-icon-delete"></i>{" "}
                    {this.props.t("actions.delete")}
                  </span>
                  <span
                    type="text"
                    onClick={() => this.props.onUserNotify(data.id)}
                  >
                    <i className="far fa-bell"></i>{" "}
                    {this.props.t("actions.notify")}
                  </span>
                </div>
              }
            >
              <i style={{ cursor: "pointer" }} className="el-icon-more"></i>
            </Popover>
          );
        },
      },
    ],
  };

  render() {
    const { users } = this.props;
    const usersNeededData = users.map((user) => {
      return {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phone: user.phone,
        isAdmin: user.isAdmin ? "Admin" : "Not Admin",
        isStaff: user.isStaff ? "Staff" : "Not Staff",
        status: !user.invitation
          ? "unverified"
          : user.invitation.isCompleted
          ? "verified"
          : "pending",
      };
    });
    return (
      <Table
        key={this.state.tableKey}
        style={{ width: "100%" }}
        columns={this.state.columns}
        data={usersNeededData}
        border={true}
        emptyText={this.props.t("convo.noResult")}
      />
    );
  }
}

export default withTranslation()(UsersTable);

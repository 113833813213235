import axios from "./index";

export const getAllGrades = async () => {
  return (await axios.get("/api/grade")).data;
};
export const addGrade = async (formData) => {
  return (await axios.post("/api/grade", formData)).data;
};

export const getGrade = async (id) => {
  return (await axios.post(`/api/grade/${id}`)).data;
};

export const updateGrade = async (id, formData) => {
  return (await axios.patch(`/api/grade/${id}`, formData)).data;
};

export const deleteGrade = async (id) => {
  return (await axios.delete(`/api/grade/${id}`)).data;
};

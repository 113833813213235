import React from "react";
import ClassBubble from "./ClassBubble";
import "./ClassBubblesList.css";

export default (props) => {
  return (
    <div className="class-bubbles-list">
      {props.classes.map((e) => {
        return (
          <ClassBubble
            key={e.name + e.subject}
            {...props}
            cls={e}
          ></ClassBubble>
        );
      })}
    </div>
  );
};

import { TextField } from "@material-ui/core";
import { Loading, Message, MessageBox } from "element-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "reactstrap";
import { addGrade, deleteGrade, getAllGrades, updateGrade } from "src/api/grades";
import { AuthContext } from "src/firebase/Auth";
import GradesTable from "../components/Tables/GradesTable";

export default (props) => {
  const { t } = useTranslation();
  const [grades, setGrades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [editingGradeID, setEditingGradeID] = useState(null);
  const initialFormState = {
    name: "",
    order: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const { currentUser } = useContext(AuthContext);

  const handleAddGrade = async (invite) => {
    setLoading(true);
    addGrade(formData)
      .then((data) => {
        setGrades(prevGrades=>[...prevGrades, data].sort((a,b)=>a.order-b.order));
        setLoading(false);
        handleCloseModal();
        Message.success("Grade added successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Grade couldn't be added.",
          customClass: "message-box-class",
        });
      });
  };
  const handleEditGrade = async () => {
    setLoading(true);
    updateGrade(editingGradeID, formData)
      .then((res) => {
        console.log(res);
        setGrades(
          grades.map((grade) => {
            if (grade?.id === editingGradeID) return res;
            return grade;
          })
        );
        setLoading(false);
        handleCloseModal();
        Message.success("Grade edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Grade couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };

  const handleGradeDelete = async (gradeID) => {
    MessageBox.confirm(
      "This will permanently delete the grade. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoading(true);
        deleteGrade(gradeID)
          .then((res) => {
            setGrades(grades.filter((grade) => grade.id !== gradeID));
            Message({
              type: "success",
              message: "Grade deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Grade couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  const handleGradeEdit = async (gradeID) => {
    setEditingGradeID(gradeID);
    const editingGrade = grades.find((grade) => grade.id === gradeID);
    setFormData({
      name: editingGrade?.name ?? "",
      order: editingGrade?.order ?? "",
    });
    setAddVisible(true);
  };

  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });
    if (e.target.required) {
      if (e.target.value !== initialFormState[eName])
        setIsWrong({ ...isWrong, [eName]: false });
    }
  };

  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingGradeID(null);
    setAddVisible(false);
  };
  useEffect(() => {
    setLoading(true);
    getAllGrades()
      .then((grades) => {
        setGrades(grades);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [currentUser]);

  const validateInput = () => {
    return (
      formData.name !== initialFormState.name &&
      formData.order !== initialFormState.order
    );
  };
  const initialWrongState = {
    name: false,
    order: false,
  };
  const [isWrong, setIsWrong] = useState(initialWrongState);
  const showInputError = async () => {
    setIsWrong({
      name: formData.name === initialFormState.name,
      order: formData.order === initialFormState.order,
    });
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="default"
          onClick={() => {
            setAddVisible(true);
          }}
        >
          {t("actions.add")}
          <i className="fas fa-plus" style={{ marginLeft: "10px" }}></i>
        </Button>
      </div>

      <Modal
        onClosed={handleCloseModal}
        className="modal-dialog-centered"
        isOpen={addVisible}
        toggle={() => {
          setAddVisible(!addVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editingGradeID ? t("actions.edit") : t("actions.add")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div style={{ padding: "0px 20px" }}>
          <TextField
            required
            error={isWrong.name}
            label={t("userData.name")}
            helperText={isWrong.name ? "Name is required." : ""}
            variant="outlined"
            type="name"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
            style={{ marginBottom: "10px" }}
          />

          <TextField
            required
            error={isWrong.order}
            label={t("userData.order")}
            helperText={isWrong.order ? "Order is required." : ""}
            variant="outlined"
            type="number"
            name="order"
            value={formData.order}
            onChange={handleFormChange}
            style={{ marginBottom: "10px" }}
          />
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={handleCloseModal}>
            {t("actions.cancel")}
          </Button>

          {editingGradeID ? (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleEditGrade : showInputError}
            >
              {t("actions.ok")}
            </Button>
          ) : (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleAddGrade : showInputError}
            >
              {t("actions.add")}
            </Button>
          )}
        </div>
      </Modal>

      <div className="list-of-Grades">
        <Loading loading={loading}>
          <GradesTable
            grades={grades}
            onGradeDelete={handleGradeDelete}
            onGradeEdit={handleGradeEdit}
          />
        </Loading>
      </div>
    </div>
  );
};

import { TextField } from "@material-ui/core";
import { Loading, Message, MessageBox } from "element-react";
import * as moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button, Col, Container, Input,
  InputGroup, InputGroupAddon, InputGroupText, Modal, Row
} from "reactstrap";
import {
  addRoute, addRouteStation, deleteRoute, deleteRouteStation, exchangeRouteStations, getAllRoutes, updateRoute
} from "src/api/routes";
import { getAllStations } from "src/api/stations";
import RoutesSidebar from "src/components/Sidebar/RoutesSidebar";
import { AuthContext } from "src/firebase/Auth";
import Route from "./Route";
import "./Routes.css";

export default (props) => {
  const { t } = useTranslation();
  const [routes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSelectedRoute, setLoadingSelectedRoute] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [editingRouteID, setEditingRouteID] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [allStations, setAllStations] = useState([]);

  const initialFormState = {
    name: "",
    description: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const { currentUser } = useContext(AuthContext);

  const handleAddRoute = async () => {
    setLoading(true);
    addRoute({ ...formData, createdAt: moment() })
      .then((data) => {
        setRoutes([...routes, data]);
        setLoading(false);
        handleCloseModal();
        Message.success("Route added successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Route couldn't be added.",
          customClass: "message-box-class",
        });
      });
  };
  const handleEditRoute = async () => {
    setLoading(true);
    updateRoute(editingRouteID, formData)
      .then((res) => {
        setRoutes(
          routes.map((route) => {
            if (route.id === editingRouteID) return res;
            return route;
          })
        );
        setSelectedRoute(res);
        setLoading(false);
        handleCloseModal();
        Message.success("Route edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Route couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };

  const handleSelectRoute = (route) => {
    setSelectedRoute(route);
  };

  const handleRouteEdit = async (route) => {
    setFormData({
      name: route.name,
      description: route.description ?? "",
    });
    setEditingRouteID(route.id);
    setAddVisible(true);
  };
  const handleRouteDelete = async (RouteID) => {
    MessageBox.confirm(
      "This will permanently delete the Route. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoading(true);
        deleteRoute(RouteID)
          .then((res) => {
            setRoutes(routes.filter((user) => user.id !== RouteID));
            setSelectedRoute(null);
            Message({
              type: "success",
              message: "Route deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Route couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });
    if (e.target.required) {
      if (e.target.value !== initialFormState[eName])
        setIsWrong({ ...isWrong, [eName]: false });
    }
  };

  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingRouteID(null);
    setAddVisible(false);
  };
  useEffect(() => {
    setLoading(true);
    getAllRoutes()
      .then((routes) => {
        setRoutes(routes);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    getAllStations()
      .then((stations) => setAllStations(stations))
      .catch(console.log);
  }, [currentUser]);

  const validateInput = () => {
    return formData.name !== "";
  };
  const initialWrongState = {
    name: false,
  };
  const [isWrong, setIsWrong] = useState(initialWrongState);
  const showInputError = async () => {
    setIsWrong({
      name: formData.name === initialFormState.name,
    });
  };

  // Selected Route handlers
  const exchangeSelectedRouteStations = (id1, id2) => {
    setLoadingSelectedRoute(true);
    exchangeRouteStations(id1, id2)
      .then((res) => {
        const order2 = selectedRoute.stations.find((e) => e.id === id1).order;
        const order1 = selectedRoute.stations.find((e) => e.id === id2).order;
        setRoutes(
          routes.map((route) => {
            if (route.id === selectedRoute.id)
              return {
                ...selectedRoute,
                stations: selectedRoute.stations.map((stat) => {
                  if (stat.id === id1) {
                    return { ...stat, order: order1 };
                  }
                  if (stat.id === id2) {
                    return { ...stat, order: order2 };
                  }
                  return stat;
                }),
              };
            return route;
          })
        );
      })
      .catch(console.log)
      .finally(() => {
        setLoadingSelectedRoute(false);
      });
  };

  const handleAddSelectedRouteStation = (stationID, nextOrder) => {
    setLoadingSelectedRoute(true);
    addRouteStation({
      routeId: selectedRoute.id,
      stationId: stationID,
      order: nextOrder,
    })
      .then((res) => {
        setRoutes(
          routes.map((route) => {
            if (route.id === selectedRoute.id)
              return {
                ...selectedRoute,
                stations: [...selectedRoute.stations, res],
              };
            return route;
          })
        );
      })
      .catch((err) => {
        Message({
          type: "error",
          message: "Station couldn't be added",
        });
      })
      .finally(() => {
        setLoadingSelectedRoute(false);
      });
  };
  const handleDeleteSelectedRouteStation = (routeStationID) => {
    MessageBox.confirm(
      "This will permanently delete the station. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoadingSelectedRoute(true);
        deleteRouteStation(routeStationID)
          .then((res) => {
            setRoutes(
              routes.map((route) => {
                if (route.id === selectedRoute.id)
                  return {
                    ...selectedRoute,
                    stations: selectedRoute.stations.filter(
                      (e) => e.id !== routeStationID
                    ),
                  };
                return route;
              })
            );
            Message({
              type: "success",
              message: "Station deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Station couldn't be deleted.",
            });
          });
      })
      .catch(() => {})
      .finally(() => {
        setLoadingSelectedRoute(false);
      });
  };
  useEffect(() => {
    if (selectedRoute) {
      setSelectedRoute(routes.find((e) => e.id === selectedRoute.id));
    }
  }, [routes, selectedRoute]);

  return (
    <div>
      <div className="search-and-add">
        <div style={{ width: "25rem", maxWidth: "75%", display: "flex" }}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            {/* TODO: Invoke getAllRoutes function with the search query */}
            <Input
              placeholder={t("actions.search")}
              type="text"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
              }}
            />
          </InputGroup>
          <Button
            style={{ margin: "0px 10px" }}
            color="default"
            onClick={() => {
              setLoading(true);
              getAllRoutes(searchValue)
                .then((Routes) => {
                  setRoutes(Routes);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                });
            }}
          >
            {t("actions.search")}
          </Button>

          <Button
            onClick={() => {
              setLoading(true);
              getAllRoutes()
                .then((Routes) => {
                  setRoutes(Routes);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                });
              setSelectedRoute(null);
            }}
          >
            <i className="fas fa-redo-alt"></i>
          </Button>
        </div>
        <Button
          color="secondary"
          outline
          onClick={() => {
            setAddVisible(true);
          }}
        >
          {t("actions.add")}
          <i className="fas fa-user-plus"></i>
        </Button>
      </div>

      <Modal
        onClosed={handleCloseModal}
        className="modal-dialog-centered"
        isOpen={addVisible}
        toggle={() => {
          setAddVisible(!addVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editingRouteID ? t("actions.edit") : t("actions.add")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="add-a-new-route">
          <TextField
            label={t("userData.name")}
            required
            error={isWrong.name}
            helperText={isWrong.name ? "Name is required." : ""}
            variant="outlined"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
          />
          <TextField
            label="Description"
            variant="outlined"
            type="text"
            name="description"
            value={formData.description}
            onChange={handleFormChange}
          />
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={handleCloseModal}>
            {t("actions.cancel")}
          </Button>

          {editingRouteID ? (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleEditRoute : showInputError}
            >
              {t("actions.ok")}
            </Button>
          ) : (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleAddRoute : showInputError}
            >
              {t("actions.add")}
            </Button>
          )}
        </div>
      </Modal>

      <div className="list-of-Routes">
        <Loading loading={loading}>
          <Container fluid>
            <Row>
              <Col className="col-4 m-0 p-0">
                <RoutesSidebar
                  routes={routes}
                  selectedRoute={selectedRoute}
                  onSelectRoute={handleSelectRoute}
                />
              </Col>
              <Col className="col-8 m-0 p-0">
                <Route
                  selectedRoute={selectedRoute}
                  onRouteDelete={handleRouteDelete}
                  onRouteEdit={handleRouteEdit}
                  allStations={allStations}
                  handleAddSelectedRouteStation={handleAddSelectedRouteStation}
                  handleDeleteSelectedRouteStation={
                    handleDeleteSelectedRouteStation
                  }
                  exchangeSelectedRouteStations={exchangeSelectedRouteStations}
                  loading={loadingSelectedRoute}
                />
              </Col>
            </Row>
          </Container>
        </Loading>
      </div>
    </div>
  );
};

import { Table } from "element-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import CheckStatus from "../Generic/CheckStatus";
import * as moment from "moment";
// import "./TasksTable.css";

class TasksTable extends Component {
  state = {
    columns: [
      {
        label: "Name",
        prop: "name",
        width: 100,
      },
      {
        label: " Description",
        prop: "description",
        width: 230,
      },
      {
        label: "File",
        prop: "file",
        width: 100,
        align: "center",
      },
      {
        label: "Assigned Date",
        prop: "assignedDate",
        width: 200,
        render: (data) => {
          return (
            <span>
              {moment(data.assignedDate).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          );
        },
      },
      {
        label: "Deadline",
        prop: "deadline",
        width: 200,
        render: (data) => {
          return (
            <span>
              {moment(data.deadline).format("MMMM Do YYYY, h:mm:ss a")}
            </span>
          );
        },
      },
      {
        label: "Online",
        prop: "isOnline",
        width: 100,
        align: "center",
        render: (data) => {
          if (data.isOnline === true) return <CheckStatus status={true} />;
          else return <CheckStatus status={false} />;
        },
      },
    ],
  };

  render() {
    const { tasks } = this.props;
    return (
      <Table
        style={{ width: "100%" }}
        columns={this.state.columns}
        data={tasks}
        border={true}
        emptyText={this.props.t("convo.noResult")}
      />
    );
  }
}

export default withTranslation()(TasksTable);

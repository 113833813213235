import React from "react";

import { NavLink as NavLinkRRD, withRouter } from "react-router-dom";
// nodejs library to set properties for components
import { Switch } from "element-react";
import { withTranslation } from "react-i18next";

//firebase
import app from "../../firebase/base";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import ProfileModal from "../Modals/ProfileModal";

class UserBubble extends React.Component {
  state = {
    collapseOpen: false,
    radioValue: (this.props.currentUser || {}).lang || "en",
    visibleProfileModal: false,
  };
  handleCloseProfileModal = () => {
    this.setState({ visibleProfileModal: false });
  };
  toggleProfileModal = () => {
    this.setState((prevState) => {
      return {
        visibleProfileModal: !prevState.visibleProfileModal,
      };
    });
  };
  onChangeRadio = (value) => {
    console.log("ISSIDE", this.props.isSide);
    console.log("ISTEACHER", this.props.teacher);
    this.props.i18n.changeLanguage(value);
    this.setState({ radioValue: value });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) =>
    window.location.pathname.indexOf(routeName) > -1 ? "active" : "";

  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false,
    });
  };
  // creates the links that appear in the left menu / Sidebar
  createLinks = (routes) => {
    const { t } = this.props;
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {t(prop.translationKey)}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { currentUser, isSide, t, forteacher } = this.props;

    return (
      <Nav
        className={
          forteacher
            ? "align-items-center"
            : isSide
            ? "align-items-center d-md-none"
            : "align-items-center d-none d-md-flex"
        }
      >
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <Media className="align-items-center">
              <h1
                className="picture-initials"
                style={{ padding: "10px", fontSize: "15px" }}
              >
                {currentUser &&
                  currentUser.firstName &&
                  !currentUser.firstName.isEmpty &&
                  currentUser.firstName.charAt(0).toUpperCase()}
                {currentUser &&
                  currentUser.lastName &&
                  !currentUser.lastName.isEmpty &&
                  currentUser.lastName.charAt(0).toUpperCase()}
              </h1>
            </Media>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem className="noti-title" header tag="div">
              <h6 className="text-overflow m-0">
                {t("convo.welcome")}{" "}
                {currentUser.firstName || currentUser.lastName}
              </h6>
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                this.setState({ visibleProfileModal: true });
              }}
            >
              <i className="ni ni-single-02" />
              {t("convo.myProfile")}
            </DropdownItem>
            <DropdownItem divider />
            <div style={{ margin: "10px", textAlign: "center" }}>
              <Switch
                value={this.state.radioValue}
                width={90}
                onValue="en"
                offValue="ar"
                onText={t("languages.en")}
                offText={t("languages.ar")}
                onChange={this.onChangeRadio}
              ></Switch>
            </div>
            <DropdownItem divider />
            {currentUser && currentUser.isStaff && currentUser.teacher && (
              <div
                style={{
                  margin: "10px 60px",
                  marginBottom: "5px",
                  display: "flex",
                }}
              >
                <span style={{ marginRight: "10px" }}>
                  {t("userData.admin")}
                </span>
                <Switch
                  value={!window.location.pathname.startsWith("/admin")}
                  onChange={() => {
                    window.location.pathname.startsWith("/admin")
                      ? this.props.history.push("/teacher")
                      : this.props.history.push("/admin");
                  }}
                  onColor="#13ce66"
                  offColor="#13ce66"
                  onText=""
                  offText=""
                  onIconClass="fas fa-user-lock"
                  offIconClass="fas fa-chalkboard-teacher"
                  width={50}
                ></Switch>
                <span style={{ marginLeft: "10px" }}>
                  {t("userData.teacher")}
                </span>
              </div>
            )}
            {currentUser && currentUser.isStaff && currentUser.teacher && (
              <DropdownItem divider />
            )}
            <DropdownItem
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                app.auth().signOut();
              }}
            >
              <i className="ni ni-user-run" />
              <span>{t("actions.logout")}</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <ProfileModal
          visibleProfileModal={this.state.visibleProfileModal}
          handleCloseProfileModal={this.handleCloseProfileModal}
          toggleProfileModal={this.toggleProfileModal}
          currentUser={currentUser}
        />
      </Nav>
    );
  }
}

export default withTranslation()(withRouter(UserBubble));

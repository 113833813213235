import axios from "./index";

export const getAllTrips = async (fromDate, toDate) => {
  return (await axios.get("/api/trip", { params: { fromDate, toDate } })).data;
};

export const getAllExceptions = async () => {
  return (await axios.get("/api/trip/exceptions")).data;
};

export const saveTrip = async (tripDate) => {
  return (await axios.post("/api/trip", tripDate)).data;
};

export const deleteTrip = async (tripId) => {
  return (await axios.delete(`/api/trip/${tripId}`)).data;
};

export const getAllTripTemplates = async () => {
  return (await axios.get("/api/trip/template")).data;
};

export const addTripTemplate = async (tripDate) => {
  return (await axios.post("/api/trip/template", tripDate)).data;
};

export const editTripTemplate = async (tripId, tripDate) => {
  return (await axios.patch(`/api/trip/template/${tripId}`, tripDate)).data;
};

export const deleteTripTemplate = async (templateId) => {
  return (await axios.delete(`/api/trip/template/${templateId}`)).data;
};

export const getVacations = async () => {
  return (await axios.get("/api/vacation")).data;
};

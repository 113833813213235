import axios from "./index";

export const getAllDrivers = async () => {
  return (await axios.get("/api/driver")).data;
};

export const addDriver = async (data) => {
  return (await axios.post("/api/driver", data)).data;
};

export const updateDriver = async (id, data) => {
  return (await axios.patch(`/api/driver/${id}`, data)).data;
};

export const deleteDriver = async (id) => {
  return (await axios.delete(`/api/driver/${id}`)).data;
};

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Table, Tag } from "element-react";
import * as moment from "moment";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { computeRepStatement, computeVacationStatement } from "src/utils/templates";

class TripTemplateTable extends Component {
  componentDidUpdate(prevProps) {
    if (prevProps.t !== this.props.t) {
      this.setState(
        (prev) => {
          prev.columns.map((e) => {
            e.label = this.props.t(e.translationKey);
            return e;
          });
          return prev;
        },
        () => {
          this.setState({ tableKey: Math.random() });
        }
      );
    }
  }
  state = {
    tableKey: 0,
    anchorEl: null,
    templateId: 0,
    clashRowID: null,
    columns: [
      {
        label: this.props.t("trip.repeatMode"),
        translationKey: "trip.repeatMode",
        width: 300,
        render: (data) => {
          return (
            <span>
              {`${computeRepStatement(data, this.props.t)} ${this.props.t(
                "trip.atTime"
              )} ${moment.parseZone(data.time).format("hh:mm:ss a")}`}
            </span>
          );
        },
      },
      {
        label: this.props.t("trip.route"),
        translationKey: "trip.route",
        render: (data) => {
          return <span>{data.route && data.route.name}</span>;
        },
      },
      {
        label: this.props.t("trip.isGoing"),
        translationKey: "trip.isGoing",
        render: (data) => {
          return (
            <Tag type={data.isGoing ? "success" : "warning"}>
              {data.isGoing ? (
                <i
                  style={{ marginRight: "10px" }}
                  className="fas fa-level-up-alt"
                ></i>
              ) : (
                <i
                  style={{ marginRight: "10px" }}
                  className="fas fa-level-down-alt"
                ></i>
              )}
              {data.isGoing
                ? this.props.t("trip.toSchool")
                : this.props.t("trip.fromSchool")}
            </Tag>
          );
        },
      },
      {
        label: this.props.t("trip.exceptedVacations"),
        translationKey: "trip.exceptedVacations",
        align: "center",
        render: (data) => {
          const exceptedVacations = computeExceptedVacations(
            data,
            this.props.t
          );
          return (
            <span>
              {exceptedVacations && !exceptedVacations.length ? (
                <Tag type="success">{this.props.t("convo.nothing")}</Tag>
              ) : (
                exceptedVacations.map((e) => (
                  <Tag key={e.id} type="warning">{`${e.name} (${e.time})`}</Tag>
                ))
              )}
            </span>
          );
        },
      },
      {
        label: this.props.t("trip.cancels"),
        translationKey: "trip.cancels",
        align: "center",
        render: (data) => {
          const cancels = computeCancels(data.cancels, this.props.templates);
          // const cancels = data.cancels;
          return (
            <span>
              {!cancels ? (
                <Tag type="success">{this.props.t("convo.nothing")}</Tag>
              ) : (
                <span
                  key={cancels.id}
                  onClick={() => {
                    this.setState({ clashRowID: cancels.id }, () => {
                      setTimeout(() => {
                        this.setState({ clashRowID: null });
                      }, 1000);
                    });
                  }}
                >
                  <Tag
                    type="danger"
                    style={{
                      cursor: "pointer",
                    }}
                  >{`${cancels.route.name},${moment.parseZone(cancels.time).format(
                    "hh:mm:ss a"
                  )}`}</Tag>
                </span>
              )}
            </span>
          );
        },
      },
      {
        label: this.props.t("trip.driver"),
        translationKey: "trip.driver",
        width: 150,
        render: (data) => {
          return <span>{data.driver && data.driver.name}</span>;
        },
      },
      {
        label: this.props.t("trip.bus"),
        translationKey: "trip.bus",
        width: 150,
        render: (data) => {
          return <span>{data.bus && data.bus.plate}</span>;
        },
      },
      {
        label: " ",
        width: 70,
        align: "center",
        translationKey: " ",
        render: (data) => {
          return (
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(ev) =>
                this.setState({
                  anchorEl: ev.currentTarget,
                  templateId: data.id,
                })
              }
            >
              <i className="fas fa-ellipsis-v"></i>
            </IconButton>
          );
        },
      },
    ],
  };

  render() {
    const { templates, onTemplateEdit, onTemplateDelete, t } = this.props;

    return (
      <>
        <Table
          key={this.state.tableKey}
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={templates}
          border={false}
          emptyText={this.props.t("convo.noResult")}
          rowStyle={(row) => {
            if (row.id === this.state.clashRowID)
              return { backgroundColor: "rgba(255, 236, 236, 0.9)" };
            return {};
          }}
        />
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() =>
            this.setState({
              anchorEl: null,
              templateId: 0,
            })
          }
        >
          {["edit", "delete"].map((option) => (
            <MenuItem
              key={option}
              onClick={() => {
                if (option === "edit") {
                  onTemplateEdit(this.state.templateId);
                } else if (option === "delete") {
                  onTemplateDelete(this.state.templateId);
                }
                this.setState({ anchorEl: null, templateId: 0 });
              }}
            >
              {t(`actions.${option}`)}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default withTranslation()(TripTemplateTable);

const computeExceptedVacations = (curr, t) =>
  curr.exceptedVacations
    .filter((vc) => vc.forTrips)
    .map((vc) => {
      const time = computeVacationStatement(vc, t);
      return { ...vc, time };
    });

const computeCancels = (cancelID, all) => {
  return all.find((e) => e.id === cancelID);
};

import { MenuItem, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Loading, Message, MessageBox } from "element-react";
import * as moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Col,
    Container,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    Row
} from "reactstrap";
import { getAllGrades } from "src/api/grades";
import { getAllStations } from "src/api/stations";
import {
    addStudent,
    deleteStudent,
    deleteStudentImage,
    getAllStudents,
    getAvailableCards,
    updateStudent,
    updateStudentImage
} from "src/api/students";
import useStateWithPromise from "src/components/Hooks/useStateWithPromise";
import StudentsSidebar from "src/components/Sidebar/StudentsSidebar";
import { AuthContext } from "src/firebase/Auth";
import { validateEmail } from "src/utils/helpers";
import Student from "./Student";
import "./Students.css";

const Students = (props) => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [editingStudentID, setEditingStudentID] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [selectedStudent, setSelectedStudent] = useState(null);

  const initialFormState = {
    name: "",
    email: "",
    phone: "",
    gradeId: "",
    station: "",
    birthDate: null,
    cardCode: null,
  };
  const [formData, setFormData] = useState(initialFormState);
  const initialWrongState = {
    name: false,
    email: false,
    gradeId: false,
    birthDate: false,
    station: false,
  };
  const [isWrong, setIsWrong] = useStateWithPromise(initialWrongState);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const [cardOptions, setCardOptions] = useState([]);
  const { currentUser } = useContext(AuthContext);

  const handleAddStudent = async () => {
    setLoading(true);
    const data = {
      ...formData,
      ...(formData.birthDate ? { birthDate: moment(formData.birthDate).toISOString() } : {}),
    }
    addStudent(data)
      .then((data) => {
        setStudents([...students, data]);
        setLoading(false);
        handleCloseModal();
        Message.success("Student added successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Student couldn't be added.",
          customClass: "message-box-class",
        });
      });
  };
  const handleEditStudent = async (editingStudentID, formData) => {
    setLoading(true);
    const data = {
      ...formData,
      ...(formData.birthDate ? { birthDate: moment(formData.birthDate).toISOString() } : {}),
    }
    updateStudent(editingStudentID, data)
      .then((res) => {
        setStudents(
          students.map((user) => {
            if (user.id === editingStudentID) return res;
            return user;
          })
        );
        setSelectedStudent(res);
        setLoading(false);
        handleCloseModal();
        Message.success("Student edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Student couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };

  const handleSelectStudent = (student) => {
    setSelectedStudent(student);
  };

  const handleStudentDelete = async (studentID) => {
    MessageBox.confirm(
      "This will permanently delete the Student. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoading(true);
        deleteStudent(studentID)
          .then((res) => {
            setStudents(students.filter((user) => user.id !== studentID));
            Message({
              type: "success",
              message: "Student deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Student couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });
    if (e.target.name === "gradeId") setIsWrong({ ...isWrong, gradeId: false });
    if (e.target.required) {
      if (eName === "email") {
        if (validateEmail(e.target.value))
          setIsWrong({ ...isWrong, email: false });
      } else {
        if (e.target.value !== initialFormState[eName])
          setIsWrong({ ...isWrong, [eName]: false });
      }
    }
  };

  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingStudentID(null);
    setAddVisible(false);
  };
  useEffect(() => {
    setLoading(true);
    getAllStudents()
      .then((students) => {
        console.log(students);
        setStudents(students);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    //get all grades
    getAllGrades()
      .then((grades) => {
        setGradeOptions(grades);
      })
      .catch(console.log);
    //get all stations
    getAllStations()
      .then((stations) => {
        setStationOptions(stations);
      })
      .catch(console.log);
  }, [currentUser]);

  useEffect(() => {
    //get available cards
    getAvailableCards()
      .then((res) => {
        setCardOptions(res);
      })
      .catch(console.log);
  }, [students]);

  const handlePhotoDelete = (id) => {
    MessageBox.confirm(
      "This will permanently delete the photo. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoading(true);
        deleteStudentImage(id)
          .then((res) => {
            setStudents(
              students.map((user) => {
                if (user.id === id) return { ...user, image: null };
                return user;
              })
            );
            setSelectedStudent({ ...selectedStudent, image: null });
            setLoading(false);
            Message.success("Image Uploaded!");
          })
          .catch((err) => {
            setLoading(false);
            Message.error({
              message: "Image Couldn't be Uploaded!",
              customClass: "message-box-class",
            });
          });
      })
      .catch(() => {});
  };
  const handlePhotoSubmit = (photo, id, handleCloseFn) => {
    let fd = new FormData();
    if (photo) {
      fd.append("file", photo);
      fd.append("name", photo.name);
    }
    // fd.append("createdAt", moment());

    setLoading(true);
    updateStudentImage(id, fd)
      .then((res) => {
        setStudents(
          students.map((user) => {
            if (user.id === id) return res;
            return user;
          })
        );
        setSelectedStudent({ ...selectedStudent, image: res.image });
        setLoading(false);
        handleCloseFn();
        Message.success("Image Uploaded!");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Image Couldn't be Uploaded!",
          customClass: "message-box-class",
        });
      });
  };

  const validateInput = () => {
    return validateEmail(formData.email);
  };

  const showInputError = async () => {
    console.log(
      "showInputError",
      validateEmail(formData.email),
      formData.gradeId !== initialFormState.gradeId
    );
    setIsWrong({
      email: !validateEmail(formData.email),
      name: formData.name === initialFormState.name,
      gradeId: formData.gradeId === initialFormState.gradeId,
    });
  };

  return (
    <div>
      <div className="search-and-add">
        <div style={{ width: "25rem", maxWidth: "75%", display: "flex" }}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fas fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            {/* TODO: Invoke getAllstudents function with the search query */}
            <Input
              placeholder={t("actions.search")}
              type="text"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
              }}
            />
          </InputGroup>
          <Button
            style={{ margin: "0px 10px" }}
            color="default"
            onClick={() => {
              setLoading(true);
              getAllStudents(searchValue)
                .then((students) => {
                  setStudents(students);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                });
            }}
          >
            {t("actions.search")}
          </Button>

          <Button
            onClick={() => {
              setLoading(true);
              getAllStudents()
                .then((students) => {
                  setStudents(students);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                });
              setSelectedStudent(null);
            }}
          >
            <i className="fas fa-redo-alt"></i>
          </Button>
        </div>
        <Button
          color="secondary"
          outline
          onClick={() => {
            setAddVisible(true);
          }}
        >
          {t("actions.add")}
          <i className="fas fa-user-plus"></i>
        </Button>
      </div>

      <Modal
        onClosed={handleCloseModal}
        className="modal-dialog-centered"
        isOpen={addVisible}
        toggle={() => {
          setAddVisible(!addVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editingStudentID ? t("actions.edit") : t("actions.add")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="add-a-new-student">
          <TextField
            required
            error={isWrong.name}
            label={t("userData.name")}
            helperText={isWrong.name ? "Name is required" : ""}
            variant="outlined"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleFormChange}
          />

          <TextField
            required
            error={isWrong.email}
            label={t("userData.email")}
            helperText={isWrong.email ? "Invalid email." : ""}
            variant="outlined"
            type="email"
            name="email"
            placeholder="user@email.com"
            value={formData.email}
            onChange={handleFormChange}
          />

          <TextField
            label={t("userData.phone")}
            variant="outlined"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleFormChange}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "10px",
            }}
          >
            <TextField
              name="gradeId"
              style={{ width: "200px" }}
              select
              variant="outlined"
              label={t("userData.grade")}
              required={true}
              value={formData.gradeId}
              helperText={isWrong.gradeId ? "Grade is required." : ""}
              error={isWrong.gradeId}
              onChange={handleFormChange}
            >
              {gradeOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              required
              name="station"
              style={{ width: "200px" }}
              select
              variant="outlined"
              label={t("userData.station")}
              value={formData.station}
              helperText={isWrong.station ? "Station is required." : ""}
              error={isWrong.station}
              onChange={handleFormChange}
            >
              {stationOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </TextField>

            {/* <TextField
              style={{ width: "200px" }}
              select
              variant="outlined"
              label={t("userData.card")}
              value={formData.cardCode}
              onChange={(e) => {
                setFormData({ ...formData, cardCode: e.target.value });
              }}
            >
              {cardOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.code}
                  </MenuItem>
                );
              })}
            </TextField> */}

            <Autocomplete
              disablePortal
              freeSolo
              options={cardOptions}
              inputValue={formData.cardCode || ""}
              onInputChange={(_, val) => {
                setFormData({ ...formData, cardCode: val || null });
              }}
              sx={{ width: "200px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ width: "200px" }}
                  variant="outlined"
                  label={t("userData.card")}
                />
              )}
              getOptionLabel={(option) => option.code || ""}
            />

            <TextField
              required
              label={t("userData.birthDate")}
              type="date"
              variant="outlined"
              value={moment(formData.birthDate).format("yyyy-MM-DD")}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  birthDate: e.target.value
                    ? moment(e.target.value).valueOf()
                    : null,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={handleCloseModal}>
            {t("actions.cancel")}
          </Button>

          {editingStudentID ? (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleEditStudent : showInputError}
            >
              {t("actions.ok")}
            </Button>
          ) : (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleAddStudent : showInputError}
            >
              {t("actions.add")}
            </Button>
          )}
        </div>
      </Modal>

      <div className="list-of-Students">
        <Loading loading={loading}>
          <Container fluid>
            <Row>
              <Col className="col-3 m-0 p-0">
                <StudentsSidebar
                  students={students}
                  selectedStudent={selectedStudent}
                  onSelectStudent={handleSelectStudent}
                />
              </Col>
              <Col className="col-9 m-0 p-0">
                <Student
                  selectedStudent={selectedStudent}
                  onStudentDelete={handleStudentDelete}
                  onSubmitPhoto={handlePhotoSubmit}
                  onDeletePhoto={handlePhotoDelete}
                  onStudentEdit={handleEditStudent}
                  gradeOptions={gradeOptions}
                  cardOptions={
                    selectedStudent && selectedStudent.card
                      ? [...cardOptions, selectedStudent.card]
                      : cardOptions
                  }
                />
              </Col>
            </Row>
          </Container>
        </Loading>
      </div>
    </div>
  );
};

export default Students;

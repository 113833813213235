import React from "react";
import { connect } from "react-redux";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import withUserHOC from "../components/Generic/withUserHOC.js";
import { storeUser } from "../store/actions/users";
import "./Teacher.css";

import { withTranslation } from "react-i18next";
import { Loading } from "element-react";
import ClassBubblesList from "src/components/Headers/ClassBubblesList.js";
import Class from "src/views/Class.js";

class Teacher extends React.Component {
  constructor() {
    super();
    this.mainContent = React.createRef();
    this.state = {
      loading: true,
      classes: [],
      selectedClass: {},
    };
  }
  componentDidMount() {
    const { i18n } = this.props;
    const user = this.props.currentUser;
    if (user) {
      i18n.changeLanguage(user.lang);
      this.props.storeUser(user);
    }

    const reacievedClasses = [
      {
        id: 1,
        name: "6D",
        students: [],
        subject: "Drama",
      },
      {
        id: 2,
        name: "7C",
        students: [],
        subject: "Drama",
      },
      {
        id: 3,
        name: "1A",
        students: [],
        subject: "Drama",
      },
      {
        id: 4,
        name: "6A",
        students: [],
        subject: "Drama",
      },
      {
        id: 5,
        name: "5B",
        students: [],
        subject: "Drama",
      },
      {
        id: 6,
        name: "5C",
        students: [],
        subject: "Drama",
      },
      {
        id: 7,
        name: "8A",
        students: [],
        subject: "Drama",
      },
      {
        id: 8,
        name: "9A",
        students: [],
        subject: "Drama",
      },
      {
        id: 9,
        name: "5A",
        students: [],
        subject: "Drama",
      },
      {
        id: 10,
        name: "5D",
        students: [],
        subject: "Drama",
      },
    ];
    this.setState({ classes: reacievedClasses }, () => {
      this.setState({ selectedClass: this.state.classes[0] }, () => {
        this.setState({ loading: false });
      });
    });
  }
  componentDidUpdate() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (this.mainContent.current) this.mainContent.current.scrollTop = 0;
  }
  handleChangeClass = (classID) => {
    this.setState({
      selectedClass: this.state.classes.find((e) => e.id === classID),
    });
  };

  render() {
    return (
      <>
        <div className="main-content" ref={this.mainContent}>
          <Loading loading={this.state.loading}>
            <AdminNavbar
              {...this.props}
              brandText="Manage Classes"
              user={this.props.currentUser}
              forteacher
            />
            <div className="teacher-main-content"></div>
            <ClassBubblesList
              onChangeClass={this.handleChangeClass}
              classes={this.state.classes}
              selectedClass={this.state.selectedClass}
            />

            <div className="teacher-class-wrapper">
              <Class selectedClass={this.state.selectedClass} />
            </div>
          </Loading>
        </div>
      </>
    );
  }
}

const actionCreators = {
  storeUser,
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
  };
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withUserHOC(Teacher)));

export const dayOfMonthOptions = [
  {
    value: 1,
    translationKey: "num.1",
  },
  {
    value: 2,
    translationKey: "num.2",
  },
  {
    value: 3,
    translationKey: "num.3",
  },
  {
    value: 4,
    translationKey: "num.4",
  },
  {
    value: 5,
    translationKey: "num.5",
  },
  {
    value: 6,
    translationKey: "num.6",
  },
  {
    value: 7,
    translationKey: "num.7",
  },
  {
    value: 8,
    translationKey: "num.8",
  },
  {
    value: 9,
    translationKey: "num.9",
  },
  {
    value: 10,
    translationKey: "num.10",
  },
  {
    value: 11,
    translationKey: "num.11",
  },
  {
    value: 12,
    translationKey: "num.12",
  },
  {
    value: 13,
    translationKey: "num.13",
  },
  {
    value: 14,
    translationKey: "num.14",
  },
  {
    value: 15,
    translationKey: "num.15",
  },
  {
    value: 16,
    translationKey: "num.16",
  },
  {
    value: 17,
    translationKey: "num.17",
  },
  {
    value: 18,
    translationKey: "num.18",
  },
  {
    value: 19,
    translationKey: "num.19",
  },
  {
    value: 20,
    translationKey: "num.20",
  },
  {
    value: 21,
    translationKey: "num.21",
  },
  {
    value: 22,
    translationKey: "num.22",
  },
  {
    value: 23,
    translationKey: "num.23",
  },
  {
    value: 24,
    translationKey: "num.24",
  },
  {
    value: 25,
    translationKey: "num.25",
  },
  {
    value: 26,
    translationKey: "num.26",
  },
  {
    value: 27,
    translationKey: "num.27",
  },
  {
    value: 28,
    translationKey: "num.28",
  },
  {
    value: 29,
    translationKey: "num.29",
  },
  {
    value: 30,
    translationKey: "num.30",
  },
  {
    value: 31,
    translationKey: "num.31",
  },
];

export const dayOfWeekOptions = [
  {
    value: 0,
    translationKey: "datepicker.weeks.sun",
  },
  {
    value: 1,
    translationKey: "datepicker.weeks.mon",
  },
  {
    value: 2,
    translationKey: "datepicker.weeks.tue",
  },
  {
    value: 3,
    translationKey: "datepicker.weeks.wed",
  },
  {
    value: 4,
    translationKey: "datepicker.weeks.thu",
  },
  {
    value: 5,
    translationKey: "datepicker.weeks.fri",
  },
  {
    value: 6,
    translationKey: "datepicker.weeks.sat",
  },
];

export const monthsOfYear = [
  {
    value: 1,
    translationKey: "datepicker.months.jan",
  },
  {
    value: 2,
    translationKey: "datepicker.months.feb",
  },
  {
    value: 3,
    translationKey: "datepicker.months.mar",
  },
  {
    value: 4,
    translationKey: "datepicker.months.apr",
  },
  {
    value: 5,
    translationKey: "datepicker.months.may",
  },
  {
    value: 6,
    translationKey: "datepicker.months.jun",
  },
  {
    value: 7,
    translationKey: "datepicker.months.jul",
  },
  {
    value: 8,
    translationKey: "datepicker.months.aug",
  },
  {
    value: 9,
    translationKey: "datepicker.months.sep",
  },
  {
    value: 10,
    translationKey: "datepicker.months.oct",
  },
  {
    value: 11,
    translationKey: "datepicker.months.nov",
  },
  {
    value: 12,
    translationKey: "datepicker.months.dec",
  },
];

import { Loading, Message } from "element-react";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Button } from "reactstrap";
import { AuthContext } from "../firebase/Auth";
import app from "../firebase/base";
import "./Login.css";

const Login = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = useCallback(
    async (event) => {
      event.preventDefault();
      const { email, password } = event.target.elements;

      setLoading(true);
      await app
        .auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .then((res) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          Message.error({
            message: t(`msg.${error.code}`),
            customClass: "message-box-class",
          });
        });
    },
    [t]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to="/admin/index" />;
  }

  return (
    <div className="login-wrapper">
      <h2>{t("convo.login")}</h2>
      <form onSubmit={handleLogin}>
        <Loading loading={loading}>
          <label>
            {t("userData.email")}
            <input name="email" type="email" placeholder="email@example.com" />
          </label>
          <label>
            {t("userData.password")}
            <div className="password-input-wrapper">
              <div
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? (
                  <i className="fas fa-eye"></i>
                ) : (
                  <i className="fas fa-eye-slash"></i>
                )}
              </div>
              <input
                id="password-field"
                name="password"
                placeholder={showPassword ? "P@55w0rd" : "**********"}
                type={showPassword ? "text" : "password"}
              />
            </div>
          </label>
        </Loading>
        <Button color="success" size="lg">
          {t("actions.login")}{" "}
        </Button>
      </form>
    </div>
  );
};

export default Login;

import React from "react";

const CheckStatus = (props) => {
  if (props.status) {
    return (
      <i
        style={{
          color: "#00e676",
          fontSize: props.size || "20px",
          margin: "auto",
        }}
        className="fas fa-check-circle"
      />
    );
  }

  return (
    <i
      style={{ color: "#f44336", fontSize: props.size || "20px" }}
      className="fas fa-times-circle"
    ></i>
  );
};

export default CheckStatus;

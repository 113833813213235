import React, { useContext } from "react";
import { AuthContext } from "../../firebase/Auth";

const withUserHOC = (Component) => {
  return (props) => {
    const { currentUser } = useContext(AuthContext);

    return <Component currentUser={currentUser} {...props} />;
  };
};

export default withUserHOC;

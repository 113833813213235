import React from "react";
import withUserHOC from "../../components/Generic/withUserHOC";

// reactstrap components
import { Navbar, Container } from "reactstrap";
import { withTranslation } from "react-i18next";
import UserBubble from "../Headers/UserBubble";

class AdminNavbar extends React.Component {
  state = {
    radioValue: (this.props.currentUser || {}).lang || "en",
    visibleProfileModal: false,
  };
  onChangeRadio = (value) => {
    this.props.i18n.changeLanguage(value);
    this.setState({ radioValue: value });
  };

  render() {
    const { t, currentUser } = this.props;
    return (
      <>
        <Navbar
          className="navbar-top navbar-light"
          expand="md"
          id="navbar-main"
          transparent="false"
        >
          <Container fluid>
            <p className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
              <span
                style={{
                  color: "black",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  paddingBottom: "5px",
                  borderBottom: "solid 3px rgb(51,238,151)",
                }}
              >
                {t(this.props.brandText)}
              </span>
            </p>

            <UserBubble
              currentUser={currentUser}
              forteacher={this.props.forteacher}
            />
          </Container>
        </Navbar>
      </>
    );
  }
}

export default withTranslation()(withUserHOC(AdminNavbar));

import { Tag } from "element-react";
import React from "react";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import "./StudentsSidebar.css";

export default ({ students, onSelectStudent, selectedStudent }) => {
  const { width, height } = useWindowDimensions();
  return (
    <div
      style={{ height: `${width > 768 ? height - 156 : height - 168}px` }}
      className="students-side-bar"
    >
      {students &&
        students.map((student) => {
          return (
            <div
              className="student-tile"
              id={
                selectedStudent &&
                (selectedStudent.id === student.id
                  ? "student-tile-selected"
                  : "")
              }
              onClick={() => onSelectStudent(student)}
            >
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {student.name}
                <Tag type="info">
                  {student.grade ? (student.grade?.name?.toLowerCase()?.includes("grade")
                    ? student.grade?.name
                    : `${student.grade?.name ?? ''} grade`) : '-'}
                </Tag>
              </p>
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  color: "#777",
                }}
              >
                {student.email}
              </p>
            </div>
          );
        })}
    </div>
  );
};

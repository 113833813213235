import React from "react";
import { Button } from "reactstrap";
import "./ClassBubble.css";

export default ({
  cls: { id, name, subject },
  selectedClass,
  onChangeClass,
}) => {
  // const [displayName, setDisplayName] = useState(name);
  return (
    <Button
      className="class-bubble"
      color={selectedClass.id === id ? "default" : ""}
      onClick={() => onChangeClass(id)}
    >
      {name} <span>{subject}</span>
    </Button>
  );
};

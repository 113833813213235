import { Table } from "element-react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "./StudentsClassList.css";

class StudentsClassList extends Component {
  state = {
    columns: [
      {
        type: "expand",
        expandPannel: function (data) {
          return (
            <div className="student-info-wrapper">
              <div className="student-info-details">
                <p>
                  <span>Email:</span> {data.email}
                </p>
                <p>
                  <span>Phone:</span> {data.phone}
                </p>
              </div>
              <img src={data.image} alt="not found" />
            </div>
          );
        },
      },
      {
        label: "Name",
        prop: "name",
        // width: 100,
      },
      {
        label: "Grade",
        prop: "grade",
        // width: 100,
      },
    ],
  };

  render() {
    const { students } = this.props;
    return (
      <div>
        <Table
          style={{ width: "100%" }}
          columns={this.state.columns}
          data={students}
          border={false}
          emptyText={this.props.t("convo.noResult")}
        />
      </div>
    );
  }
}

export default withTranslation()(StudentsClassList);

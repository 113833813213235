import { Loading, Message, MessageBox, Switch } from "element-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "reactstrap";
import { getAllBuses } from "src/api/buses";
import { getAllDrivers } from "src/api/drivers";
import { getAllRoutes } from "src/api/routes";
import {
  addTripTemplate,
  deleteTripTemplate,
  editTripTemplate,
  getAllTripTemplates,
  getVacations
} from "src/api/trips";
import TripTemplateTable from "src/components/Tables/TripTemplateTable";
import {
  dayOfMonthOptions,
  dayOfWeekOptions,
  monthsOfYear
} from "src/utils/constants";
import { TemplateRepeatMode } from "src/utils/templates";
import "./Templates.css";

import { makeStyles, MenuItem, TextField } from "@material-ui/core";
import AutoComplete from "@material-ui/lab/Autocomplete";
import * as moment from "moment";

export default () => {
  const { t } = useTranslation();
  const classes = useStyles();

  //state
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingTemplateID, setEditingTemplateID] = useState(null);
  const [addVisible, setAddVisible] = useState(false);
  const [modeOptions] = useState([
    {
      translationKey: "trip.daily",
      value: TemplateRepeatMode.DAILY,
    },
    {
      translationKey: "trip.weekly",
      value: TemplateRepeatMode.WEEKLY,
    },
    {
      translationKey: "trip.monthly",
      value: TemplateRepeatMode.MONTHLY,
    },
    {
      translationKey: "trip.yearly",
      value: TemplateRepeatMode.YEARLY,
    },
    {
      translationKey: "trip.weekDayPerMonth",
      value: TemplateRepeatMode.WEEK_DAY_PER_MONTH,
    },
  ]);
  const [routeOptions, setRouteOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [busOptions, setBusOptions] = useState([]);
  const [vacationOptions, setVacationOptions] = useState([]);
  const [cancelsOptions, setCancelsOptions] = useState([]);

  const initialFormState = {
    time: moment().format("hh:mm").toString(),
    month: 0,
    dayOfMonth: 0,
    dayOfWeek: 0,
    routeId: null,
    busId: null,
    driverId: null,
    isGoing: false,
    repeatMode: TemplateRepeatMode.DAILY,
    cancelsId: null,
    exceptedVacations: [],
  };
  const [formData, setFormData] = useState(initialFormState);

  //handlers
  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingTemplateID(null);
    setAddVisible(false);
  };

  //TODO: editing gives an error in the database "tripTemplateId not found"
  const handleEditTemplate = () => {
    setLoading(true);
    editTripTemplate(editingTemplateID, {
      ...formData,
      time: moment(formData.time, "hh:mm").format("HH:mm"),
      exceptedVacations: formData.exceptedVacations.map((e) => e.id),
    })
      .then((res) => {
        setTemplates(
          templates.map((template) => {
            if (template.id === editingTemplateID) return res;
            return template;
          })
        );

        setLoading(false);
        handleCloseModal();
        Message.success("Template edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Template couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };
  const handleAddTemplate = () => {
    setLoading(true);
    addTripTemplate(formData)
      .then((addedTrip) => {
        setTemplates([...templates, addedTrip]);
        Message.success({
          message: "Template Added successfully",
          customClass: "message-box-class",
        });
      })
      .catch((err) => {
        Message.error({
          message: "Template couldn't be added",
          customClass: "message-box-class",
        });
      })
      .finally(() => {
        handleCloseModal();
        setLoading(false);
      });
  };
  const handleTemplateDelete = (id) => {
    MessageBox.confirm(t("msg.deleteTemplte"), "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning",
    })
      .then(() => {
        setLoading(true);
        deleteTripTemplate(id)
          .then((res) => {
            setTemplates(templates.filter((template) => template.id !== id));
            Message({
              type: "success",
              message: "Template deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Template couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };
  const handleTemplateEdit = (id) => {
    setEditingTemplateID(id);
    const editingTemplate = templates.find((template) => template.id === id);
    setFormData({
      time:
        typeof editingTemplate.time === "string" &&
        editingTemplate.time.length === 5
          ? initialFormState.time
          : moment.parseZone(editingTemplate.time).format("hh:mm").toString(),
      month: editingTemplate.month ?? initialFormState.month,
      dayOfMonth: editingTemplate.dayOfMonth ?? initialFormState.dayOfMonth,
      dayOfWeek: editingTemplate.dayOfWeek ?? initialFormState.dayOfWeek,
      routeId: editingTemplate.routeId ?? initialFormState.routeId,
      busId: editingTemplate.busId ?? initialFormState.busId,
      driverId: editingTemplate.driverId ?? initialFormState.driverId,
      isGoing: editingTemplate.isGoing ?? initialFormState.isGoing,
      repeatMode: editingTemplate.repeatMode ?? initialFormState.repeatMode,
      cancelsId: editingTemplate.cancelsId ?? initialFormState.cancelsId,
      exceptedVacations:
        editingTemplate.exceptedVacations ?? initialFormState.exceptedVacations,
    });
    setAddVisible(true);
  };

  //validators
  const validateInput = () => {
    const baseCheck =
      formData.routeId !== initialFormState.routeId &&
      formData.driverId !== initialFormState.driverId &&
      formData.busId !== initialFormState.busId;
    const weeklyCheck =
      baseCheck && formData.dayOfWeek !== initialFormState.dayOfWeek;
    const monthlyCheck =
      baseCheck && formData.dayOfMonth !== initialFormState.dayOfMonth;
    const monthCheck = formData.month !== initialFormState.month;
    switch (formData.repeatMode) {
      case TemplateRepeatMode.DAILY:
        return baseCheck;
      case TemplateRepeatMode.WEEKLY:
        return weeklyCheck;
      case TemplateRepeatMode.MONTHLY:
        return monthlyCheck;
      case TemplateRepeatMode.YEARLY:
        return monthCheck && monthlyCheck;
      case TemplateRepeatMode.WEEK_DAY_PER_MONTH:
        return monthCheck && weeklyCheck;
      default:
        return false;
    }
  };

  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });
    if (e.target.name === "routeId") setIsWrong({ ...isWrong, routeId: false });
    if (e.target.name === "busId") setIsWrong({ ...isWrong, busId: false });
    if (e.target.name === "driverId")
      setIsWrong({ ...isWrong, driverId: false });
    if (e.target.required) {
      if (e.target.value !== initialFormState[eName])
        setIsWrong({ ...isWrong, [eName]: false });
    }
  };
  const initialWrongState = {
    date: false,
    routeId: false,
    driverId: false,
    busId: false,
  };
  const [isWrong, setIsWrong] = useState(initialWrongState);
  const showInputError = async () => {
    setIsWrong({
      date: formData.date === initialFormState.date,
      routeId: formData.routeId === initialFormState.routeId,
      driverId: formData.driverId === initialFormState.driverId,
      busId: formData.busId === initialFormState.busId,
    });
  };

  // once with every component render
  useEffect(() => {
    //get all templates
    setLoading(true);
    getAllTripTemplates()
      .then((templates) => {
        console.log(templates);
        setTemplates(templates);
        setCancelsOptions(templates);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
    //get all routes
    getAllRoutes()
      .then((routes) => {
        setRouteOptions(routes);
      })
      .catch(console.log);

    //get all drivers
    getAllDrivers()
      .then((drivers) => {
        setDriverOptions(drivers);
      })
      .catch(console.log);

    //get all buses
    getAllBuses()
      .then((buses) => {
        setBusOptions(buses);
      })
      .catch(console.log);

    //get all vacations
    getVacations()
      .then((vacations) => {
        setVacationOptions(vacations);
      })
      .catch(console.log);
  }, []);

  return (
    <div>
      <div className="add-template">
        <Button
          onClick={() => {
            setAddVisible(true);
          }}
        >
          <i className="fas fa-calendar-plus"></i>
          {t("actions.add")}
        </Button>
      </div>
      <Loading loading={loading}>
        <TripTemplateTable
          templates={templates}
          onTemplateEdit={handleTemplateEdit}
          onTemplateDelete={handleTemplateDelete}
        />
      </Loading>

      <Modal
        onClosed={handleCloseModal}
        className="modal-dialog-centered"
        isOpen={addVisible}
        toggle={() => {
          setAddVisible(!addVisible);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {editingTemplateID ? t("actions.edit") : t("actions.add")}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleCloseModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="add-a-new-template">
          <div className="time-for-template">
            <div className="display-flex-and-wrap">
              <div>
                <TextField
                  type="time"
                  style={{ width: "200px", maxWidth: "90%" }}
                  label={t("trip.time")}
                  variant="outlined"
                  value={formData.time}
                  onChange={(e) => {
                    setFormData({ ...formData, time: e.target.value });
                  }}
                />
              </div>
              <div>
                <TextField
                  style={{ width: "200px", maxWidth: "90%" }}
                  label={t("trip.repeatMode")}
                  select
                  variant="outlined"
                  value={formData.repeatMode}
                  onChange={(e) => {
                    setFormData({ ...formData, repeatMode: e.target.value });
                  }}
                >
                  {modeOptions.map((el) => {
                    return (
                      <MenuItem key={el.value} value={el.value}>
                        {t(el.translationKey)}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>
            <div className="display-flex-and-wrap">
              {(formData.repeatMode === TemplateRepeatMode.YEARLY ||
                formData.repeatMode ===
                  TemplateRepeatMode.WEEK_DAY_PER_MONTH) && (
                <div>
                  <TextField
                    style={{ width: "200px", maxWidth: "90%" }}
                    label={t("trip.month")}
                    select
                    variant="outlined"
                    value={formData.month}
                    onChange={(e) => {
                      setFormData({ ...formData, month: e.target.value });
                    }}
                  >
                    {monthsOfYear.map((el) => {
                      return (
                        <MenuItem key={el.value} value={el.value}>
                          {t(el.translationKey)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}

              {(formData.repeatMode === TemplateRepeatMode.WEEKLY ||
                formData.repeatMode ===
                  TemplateRepeatMode.WEEK_DAY_PER_MONTH) && (
                <div>
                  <TextField
                    style={{ width: "200px", maxWidth: "90%" }}
                    label={t("trip.dayOfWeek")}
                    select
                    variant="outlined"
                    value={formData.dayOfWeek}
                    onChange={(e) => {
                      setFormData({ ...formData, dayOfWeek: e.target.value });
                    }}
                  >
                    {dayOfWeekOptions.map((el) => {
                      return (
                        <MenuItem key={el.value} value={el.value}>
                          {t(el.translationKey)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}

              {(formData.repeatMode === TemplateRepeatMode.MONTHLY ||
                formData.repeatMode === TemplateRepeatMode.YEARLY) && (
                <div>
                  <TextField
                    style={{ width: "200px", maxWidth: "90%" }}
                    label={t("trip.dayOfMonth")}
                    select
                    variant="outlined"
                    value={formData.dayOfMonth}
                    onChange={(e) => {
                      setFormData({ ...formData, dayOfMonth: e.target.value });
                    }}
                  >
                    {dayOfMonthOptions.map((el) => {
                      return (
                        <MenuItem key={el.value} value={el.value}>
                          {t(el.translationKey)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              )}
            </div>
          </div>

          <div className="select-option">
            <TextField
              style={{ width: "200px", maxWidth: "90%" }}
              label={t("trip.route")}
              select
              required
              error={isWrong.routeId}
              helperText={isWrong.routeId ? "Route is required." : ""}
              variant="outlined"
              value={formData.routeId}
              onChange={handleFormChange}
              name="routeId"
            >
              {routeOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className="select-option">
            <TextField
              style={{ width: "200px", maxWidth: "90%" }}
              label={t("trip.driver")}
              select
              required
              error={isWrong.driverId}
              helperText={isWrong.driverId ? "Driver is required." : ""}
              variant="outlined"
              value={formData.driverId}
              onChange={handleFormChange}
              name="driverId"
            >
              {driverOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {el.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div className="select-option">
            <TextField
              style={{ width: "200px", maxWidth: "90%" }}
              label={t("trip.bus")}
              select
              required
              error={isWrong.busId}
              helperText={isWrong.busId ? "Bus is required." : ""}
              variant="outlined"
              value={formData.busId}
              onChange={handleFormChange}
              name="busId"
            >
              {busOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {`${el.name} ${el.plate}`}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div className="select-option">
            <TextField
              style={{ width: "200px", maxWidth: "90%" }}
              label={t("trip.cancels")}
              select
              variant="outlined"
              value={formData.cancelsId}
              placeholder={t("trip.cancels")}
              onChange={(e) => {
                setFormData({ ...formData, cancelsId: e.target.value });
              }}
              name="cancelsId"
            >
              {cancelsOptions.map((el) => {
                return (
                  <MenuItem key={el.id} value={el.id}>
                    {`${el.route.name} ${moment
                      .parseZone(el.time)
                      .format("hh:mm a")}`}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>

          <div className="select-option">
            <Switch
              style={{ display: "inline-block", marginRight: "20px" }}
              value={formData.isGoing}
              onChange={(value) => {
                setFormData({ ...formData, isGoing: value });
              }}
              offColor="rgb(251,209,97)"
              onColor="rgb(19,206,102)"
              onIconClass="fas fa-level-up-alt"
              offIconClass="fas fa-level-down-alt"
            ></Switch>
            <span>
              {formData.isGoing ? t("trip.toSchool") : t("trip.fromSchool")}
            </span>
          </div>
        </div>
        <div className="select-option">
          <div
            className={classes.root}
            style={{ maxWidth: "70%", marginLeft: "20px" }}
          >
            <AutoComplete
              value={formData.exceptedVacations}
              onChange={(e, values) => {
                setFormData({
                  ...formData,
                  exceptedVacations: values,
                });
              }}
              multiple
              id="tags-outlined"
              options={vacationOptions}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Vacations" />
              )}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button color="secondary" onClick={handleCloseModal}>
            {t("actions.cancel")}
          </Button>

          {editingTemplateID ? (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleEditTemplate : showInputError}
            >
              {t("actions.ok")}
            </Button>
          ) : (
            <Button
              color={validateInput() ? "success" : "secondary"}
              onClick={validateInput() ? handleAddTemplate : showInputError}
            >
              {t("actions.add")}
            </Button>
          )}
        </div>
      </Modal>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

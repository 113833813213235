import { IconButton, Menu, MenuItem } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default ({ drivers, onDriverEdit, onDriverDelete }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [driverID, setDriverID] = useState(null);
  return (
    <div style={{ padding: "30px" }}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("userData.name")}</TableCell>
              <TableCell align="center">{t("userData.license")}</TableCell>
              <TableCell align="center">{t("userData.card")}</TableCell>
              <TableCell align="center">{t("userData.phone")}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drivers.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">{row.name}</TableCell>
                <TableCell align="center">{row.license}</TableCell>
                <TableCell align="center">{row.cardCode}</TableCell>
                <TableCell align="center">{row.phone}</TableCell>

                <TableCell align="center">
                  <IconButton
                    size="small"
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={(ev) => {
                      setDriverID(row.id);
                      setAnchorEl(ev.currentTarget);
                    }}
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </IconButton>
                  <Menu
                    style={{ color: "red" }}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                  >
                    {["edit", "delete"].map((option) => (
                      <MenuItem
                        key={option}
                        onClick={() => {
                          if (option === "edit") {
                            onDriverEdit(driverID);
                          } else if (option === "delete") {
                            onDriverDelete(driverID);
                          }
                          setAnchorEl(null);
                        }}
                      >
                        {t(`actions.${option}`)}
                      </MenuItem>
                    ))}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

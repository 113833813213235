import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import withUserHOC from "../components/Generic/withUserHOC.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { storeUser } from "../store/actions/users";
import "./Admin.css";

import routes from "../routes.js";
import { withTranslation } from "react-i18next";
import { Loading } from "element-react";

class Admin extends React.Component {
  constructor() {
    super();
    this.mainContent = React.createRef();
    this.state = {
      loading: true,
      filteredRoutes: [],
    };
  }
  componentDidMount() {
    const { i18n } = this.props;
    const user = this.props.currentUser;
    if (user) {
      i18n.changeLanguage(user.lang);
      this.props.storeUser(user);
      this.setState({
        loading: false,
        filteredRoutes: routes.filter((r) => r.isPermitted(user)),
      });
    }
  }
  componentDidUpdate() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (this.mainContent.current) this.mainContent.current.scrollTop = 0;
  }
  getRoutes = () => {
    return this.state.filteredRoutes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (prop.expendable)
          return prop.children.map((route, index) => (
            <Route
              path={prop.layout + prop.path + route.path}
              component={route.component}
              key={index}
            />
          ));

        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    });
  };
  getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (path.indexOf(routes[i].layout + routes[i].path) !== -1) {
        if (routes[i].name.toLocaleLowerCase() === "accounts")
          return "sidebar.usersManagement";
        else if (routes[i].name.toLocaleLowerCase() === "students")
          return "sidebar.studentsManagement";
        else if (routes[i].name.toLocaleLowerCase() === "drivers")
          return "sidebar.drivers";
        else if (routes[i].name.toLocaleLowerCase() === "schedule") {
          for (let j = 0; j < routes[i].children.length; j++) {
            if (
              path.split("/")[3] ===
              routes[i].children[j].name.toLocaleLowerCase()
            ) {
              console.log(routes[i].children[j].translationKey);
              return `${routes[i].children[j].translationKey}`;
            }
          }
        }
        return `sidebar.${routes[i].name.toLocaleLowerCase()}`;
      }
    }
    return "Brand";
  };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          user={this.props.user}
          routes={this.state.filteredRoutes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("../assets/img/schools.png"),
            imgAlt: "...",
          }}
        />

        <div className="main-content" ref={this.mainContent}>
          {this.state.loading ? (
            <div className="center-div">
              <Loading />
            </div>
          ) : (
            <div>
              <AdminNavbar
                {...this.props}
                brandText={this.getBrandText(this.props.location.pathname)}
                user={this.props.user}
              />

              <div className="admin-content-wrapper">
                <Switch>
                  {this.getRoutes()}
                  <Redirect from="*" to="/admin/index" />
                </Switch>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const actionCreators = {
  storeUser,
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
  };
};

export default connect(
  mapStateToProps,
  actionCreators
)(withTranslation()(withUserHOC(Admin)));

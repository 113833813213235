import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import "./Route.css";
import { Tooltip } from "@material-ui/core";
import { Tag, Loading } from "element-react";
import Map from "src/components/Generic/Map";
import useWindowDimensions from "src/components/Hooks/useWindowDimensions";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";

export default ({
  selectedRoute,
  allStations,
  handleAddSelectedRouteStation,
  handleDeleteSelectedRouteStation,
  exchangeSelectedRouteStations,
  loading,
  onRouteEdit,
  onRouteDelete,
}) => {
  const { width, height } = useWindowDimensions();
  const classes = useStyles();
  const [stations, setStations] = useState([]);
  const [stationOptions, setStationOptions] = useState([]);
  const nextOrder = useRef(1);

  useEffect(() => {
    if (selectedRoute)
      setStations(
        (selectedRoute.stations || [])
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((e) => {
            return { ...e.station, routeStationID: e.id, order: e.order };
          })
      );
  }, [selectedRoute]);

  useEffect(() => {
    if (allStations.length)
      setStationOptions(
        allStations.filter(
          (stat) => !stations.map((e) => e.id).includes(stat.id)
        )
      );
  }, [stations, allStations]);

  const handleAddStationRoute = (stationID) => {
    handleAddSelectedRouteStation(stationID, nextOrder.current);
  };

  const handleDeleteStationRoute = async (routeStationID) => {
    handleDeleteSelectedRouteStation(routeStationID);
  };

  const handleExchangeRouteStations = (id1, withWhat) => {
    exchangeSelectedRouteStations(
      id1,
      withWhat === "before"
        ? getRouteStationIdBefore(id1)
        : getRouteStationIdAfter(id1)
    );
  };

  const getRouteStationIdBefore = (id) => {
    for (var i = stations.length - 1; i >= 1; i--) {
      if (stations[i].routeStationID === id)
        return stations[i - 1].routeStationID;
    }
  };
  const getRouteStationIdAfter = (id) => {
    for (var i = 0; i < stations.length - 1; i++) {
      if (stations[i].routeStationID === id)
        return stations[i + 1].routeStationID;
    }
  };
  return (
    <div
      className="route-timeline"
      style={{
        height: `${width > 768 ? height - 156 : height - 168}px`,
      }}
    >
      {selectedRoute ? (
        <Loading loading={loading}>
          <div className="selected-route-header">
            <Button
              size="sm"
              color="default"
              className="edit-selected-header"
              onClick={() => onRouteEdit(selectedRoute)}
            >
              <i className="far fa-edit" style={{ fontSize: "15px" }} />
            </Button>
            <Button
              size="sm"
              color="warning"
              className="delete-selected-header"
              onClick={() => onRouteDelete(selectedRoute.id)}
            >
              <i className="fas fa-trash" style={{ fontSize: "15px" }} />
            </Button>
            <Paper style={{ padding: "10px" }}>
              <Typography>
                <Tag style={{ marginBottom: "5px" }}>
                  {selectedRoute && selectedRoute.name}
                </Tag>
              </Typography>
              <Typography>
                {selectedRoute && selectedRoute.description}
              </Typography>
            </Paper>
          </div>
          <Timeline align="alternate">
            <TimelineItem>
              <TimelineOppositeContent
                className={classes.verticallyCenterContent}
                align="right"
                variant="body2"
                color="textSecondary"
              >
                <Typography variant="h6" component="h1">
                  School
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <Tooltip title="Add Station" placement="right">
                  <TimelineDot
                    id="addStationRouteTarget"
                    color="inherit"
                    className="add-station-to-route"
                    style={{ color: " rgb(51,238,151)" }}
                  >
                    <i className="fas fa-school"></i>
                  </TimelineDot>
                </Tooltip>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent />
            </TimelineItem>
            {stations.map((station, i) => {
              if (i === stations.length - 1)
                nextOrder.current = station.order + 1;
              return (
                <TimelineItem key={station.id}>
                  <TimelineOppositeContent
                    className={classes.verticallyCenterContent}
                    align="right"
                    variant="body2"
                    color="textSecondary"
                  >
                    <div className="route-station-options">
                      {i !== 0 && (
                        <Tooltip
                          title="move up"
                          placement={!(i % 2) ? "bottom-start" : "bottom-end"}
                        >
                          <i
                            className="fas fa-arrow-up"
                            onClick={() =>
                              handleExchangeRouteStations(
                                station.routeStationID,
                                "before"
                              )
                            }
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        title="remove station"
                        placement={!(i % 2) ? "bottom-start" : "bottom-end"}
                      >
                        <i
                          onClick={() =>
                            handleDeleteStationRoute(station.routeStationID)
                          }
                          className="fas fa-trash"
                          style={{
                            marginRight: !(i % 2) ? "0px" : "-5px",
                            marginLeft: !(i % 2) ? "-5px" : "0px",
                          }}
                        />
                      </Tooltip>
                      {i !== stations.length - 1 && (
                        <Tooltip
                          title="move down"
                          placement={!(i % 2) ? "bottom-start" : "bottom-end"}
                        >
                          <i
                            className="fas fa-arrow-down"
                            onClick={() =>
                              handleExchangeRouteStations(
                                station.routeStationID,
                                "after"
                              )
                            }
                          />
                        </Tooltip>
                      )}
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="inherit">
                      <i className="fas fa-map-marker-alt" />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant="h6" component="h1">
                        {station.name}
                      </Typography>
                      <Typography> {station.description}</Typography>
                      <Typography>
                        {station.latitude && station.longitude ? (
                          <Map lat={station.latitude} lng={station.longitude} />
                        ) : (
                          <Tag type="warning">Not specified</Tag>
                        )}
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}

            <TimelineItem>
              <UncontrolledPopover
                trigger="legacy"
                placement="left"
                target="addStationRouteTarget"
              >
                <PopoverBody
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "100px",
                    overflowY: "auto",
                  }}
                >
                  {stationOptions.map((station) => (
                    <span
                      className="station-option"
                      onClick={() => handleAddStationRoute(station.id)}
                    >
                      {station.name}
                    </span>
                  ))}
                </PopoverBody>
              </UncontrolledPopover>
              <TimelineSeparator>
                <Tooltip title="Add Station" placement="right">
                  <TimelineDot
                    id="addStationRouteTarget"
                    color="primary"
                    className="add-station-to-route"
                  >
                    <i className="fas fa-plus" />
                  </TimelineDot>
                </Tooltip>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent />
            </TimelineItem>
          </Timeline>
        </Loading>
      ) : (
        <div className="center-div-relative">No Selected Route</div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  verticallyCenterContent: {
    margin: "auto 0",
  },
}));

import * as moment from "moment";
import { dayOfMonthOptions, dayOfWeekOptions } from "./constants";

export class TemplateRepeatMode {
  static DAILY = 0;
  static WEEKLY = 1;
  static MONTHLY = 2;
  static YEARLY = 3;
  static WEEK_DAY_PER_MONTH = 4;
}

export class VacationRepeatMode {
  static WEEKLY = 0;
  static MONTHLY = 1;
  static YEARLY = 2;
  static OneDay = 3;
}


export const computeVacationStatement = (data, t) => {
  if (data.repeatMode === VacationRepeatMode.WEEKLY) {
    const dofWeek = dayOfWeekOptions.find((e) => e.value === data.dayOfWeek);
    return t(dofWeek.translationKey);
  } else if (data.repeatMode === VacationRepeatMode.MONTHLY) {
    const dofmonth = dayOfMonthOptions.find(
      (e) => e.value === data.dayOfMonth
    );
    return t(dofmonth.translationKey);
  } else if (data.repeatMode === VacationRepeatMode.YEARLY) {
    const dayofmonth = dayOfMonthOptions.find(
      (e) => e.value === data.dayOfMonth
    );
    return `${t(dayofmonth.translationKey)}th of ${t(
      `datepicker.month${data.month}`
    )}`;
  } else if (data.repeatMode === VacationRepeatMode.OneDay) {
    return `On ${moment("dddd, MMMM Do YYYY").format(data.date)}`;
  }
}

export const computeRepStatement = (data, t) => {
  switch (data.repeatMode) {
    case TemplateRepeatMode.DAILY:
      return `${t("trip.repMsg.daily")} at ${moment.parseZone(data.time).format(
        "hh:mm:ss a"
        )}`;
    case TemplateRepeatMode.WEEKLY:
      const day = dayOfWeekOptions.find((e) => e.value === data.dayOfWeek);
      return `${t("trip.repMsg.weekly")} ${t(day.translationKey)}`;
    case TemplateRepeatMode.MONTHLY:
      const dofmonth = dayOfMonthOptions.find(
        (e) => e.value === data.dayOfMonth
      );
      return `${t("trip.repMsg.monthly")} ${t(dofmonth.translationKey)}`;
    case TemplateRepeatMode.YEARLY:
      const dayofmonth = dayOfMonthOptions.find(
        (e) => e.value === data.dayOfMonth
      );
      return `${t("trip.repMsg.yearly")} ${t(
        dayofmonth.translationKey
      )} ${moment.parseZone(data.month).format("MMMM")}`;
    case TemplateRepeatMode.WEEK_DAY_PER_MONTH:
      const dofWeek = dayOfWeekOptions.find((e) => e.value === data.dayOfWeek);
      return `${t("trip.repMsg.weekDayPerMonth1")} ${moment.parseZone(data.month).format(
        "MMMM"
      )} ${t("trip.repMsg.weekDayPerMonth2")} ${t(dofWeek.translationKey)}`;
    default:
      return t("trip.repMsg.daily");
  }
};
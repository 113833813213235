import React from "react";
import { withTranslation } from "react-i18next";

import { Container } from "reactstrap";

class Index extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <Container fluid>
          <h1>{this.props.t("convo.hello")}</h1>
        </Container>
      </>
    );
  }
}

export default withTranslation()(Index);

import axios from "./index";

export const getCurrentUser = async (idToken) => {
  if (idToken)
    return (
      await axios.get("/api/user/current", {
        headers: { Authorization: `Bearer ${idToken}` },
      })
    ).data;
  return (await axios.get("/api/user/current")).data;
};

export const updateCurrentUser = async (updatedUser) => {
  return (await axios.patch("/api/user/current", updatedUser)).data;
};

export const getAllUsers = async (query) => {
  return (await axios.get("/api/user", { params: { query } })).data;
};

export const addUser = async (formData) => {
  return (await axios.post("/api/user", formData)).data;
};

export const editUser = async (userID, formData) => {
  return (await axios.patch(`/api/user/${userID}`, formData)).data;
};

export const deleteUser = async (userID) => {
  return (await axios.delete(`/api/user/${userID}`)).data;
};

export const getAllRoles = async () => {
  return (await axios.get("/api/role")).data;
};

export const notifyUser = async (form) => {
  return (await axios.post(`/api/user/${form.userID}/notify`, form)).data;
};

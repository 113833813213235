import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import { Button } from "element-react";
import Login from "../views/Login";

import routes from "../routes.js";

class Auth extends React.Component {
  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  switchLang = (lang) => {
    this.props.i18n.changeLanguage(lang);
    this.setState({ lang });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <div className="main-content" style={{ backgroundColor: "green" }}>
          <div className="header bg-gradient-primary py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    {/* <h1 className="text-white">Welcome!</h1>
                    <p className="text-lead text-light">
                      Use these awesome forms to login or create new account in
                      your project for free.
                    </p> */}

                    <Login />
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Switch>
                {this.getRoutes(routes)}
                <Redirect from="*" to="/auth/login" />
              </Switch>
            </Row>
          </Container>
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "20px",
            right: "20px",
            padding: "20px",
          }}
        >
          <img
            style={{
              width: "150px",
            }}
            alt="Egy Schools logo"
            src={require("../assets/img/schools.png")}
          />
          <div className="lang-switcher">
            <Button.Group>
              {["en", "ar"].map((lang) => (
                <Button key={lang} onClick={() => this.switchLang(lang)}>
                  {t(`languages.${lang}`)}
                </Button>
              ))}
            </Button.Group>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(Auth);

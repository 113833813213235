import axios from "./index";

export const getAllStudents = async (query) => {
  return (await axios.get("/api/student", { params: { query } })).data;
};

export const getStudentUsers = async (studentId) => {
  return (await axios.get(`/api/student/${studentId}/users`)).data;
};

export const getStudentTrips = async (studentId) => {
  return (await axios.get(`/api/student/${studentId}/trips`)).data;
};

export const getStudentTripTemplates = async (studentId) => {
  return (await axios.get(`/api/student/${studentId}/templates`)).data;
};

export const getStudentActiveTrips = async (studentId) => {
  return (await axios.get(`/api/student/${studentId}/trips`)).data;
};

export const addStudent = async (formData) => {
  return (await axios.post("/api/student", formData)).data;
};

export const addStudentUser = async (id, formData) => {
  return (await axios.post(`/api/student/${id}/users`, formData)).data;
};

export const removeStudentUser = async (id, userId) => {
  return (await axios.delete(`/api/student/${id}/users/${userId}`)).data;
};

export const addStudentTrip = async (id, formData) => {
  return (await axios.post(`/api/student/${id}/trips`, formData)).data;
};

export const removeStudentTrip = async (id, type, tripId) => {
  return (await axios.delete(`/api/student/${id}/trips/${type}/${tripId}`)).data;
};

export const updateStudentImage = async (id, fd) => {
  return (await axios.patch(`/api/student/${id}/image`, fd)).data;
};

export const deleteStudentImage = async (id) => {
  return (await axios.delete(`/api/student/${id}/image`)).data;
};

export const deleteStudent = async (id) => {
  return (await axios.delete(`/api/student/${id}`)).data;
};

export const updateStudent = async (id, data) => {
  return (await axios.patch(`/api/student/${id}`, data)).data;
};

export const getAvailableCards = async () => {
  return (await axios.get("/api/student/cards")).data;
};

import Index from "./views/Index.js";
import Accounts from "./views/Accounts";
import Students from "./views/Students.js";
import Trips from "./views/Trips.js";
import Stations from "./views/Stations.js";
import Routes from "./views/Routes.js";
import Templates from "./views/Templates.js";
import Grades from "./views/Grades.js";
import { Drivers } from "./views/Drivers";
import { Buses } from "./views/Buses.js";

export default [
  {
    path: "/index",
    name: "Dashboard",
    translationKey: "sidebar.dashboard",
    icon: "ni ni-tv-2", //text-primary
    component: Index,
    layout: "/admin",
    isPermitted: (user) => user.isStaff,
  },
  {
    path: "/accounts",
    name: "Accounts",
    translationKey: "sidebar.usersManagement",
    icon: "fas fa-users", //text-primary
    component: Accounts,
    layout: "/admin",
    isPermitted: (user) => user.perms.includes("userView"),
  },
  {
    path: "/students",
    name: "Students",
    translationKey: "sidebar.studentsManagement",
    icon: "fas fa-user-graduate", //text-primary
    component: Students,
    layout: "/admin",
    isPermitted: (user) => user.perms.includes("studentView"),
  },
  {
    expendable: true,
    path: "/schedule",
    children: [
      {
        path: "/trips",
        component: Trips,
        name: "Trips",
        translationKey: "sidebar.trips",
        icon: "fas fa-bus", //text-primary
      },
      {
        path: "/templates",
        component: Templates,
        name: "Templates",
        translationKey: "sidebar.templates",
        icon: "far fa-calendar-alt", //text-primary
      },
      {
        path: "/routes",
        component: Routes,
        name: "Routes",
        translationKey: "sidebar.routes",
        icon: "fas fa-route", //text-primary
      },
      {
        path: "/stations",
        component: Stations,
        name: "Stations",
        translationKey: "sidebar.stations",
        icon: "fas fa-map-marker-alt", //text-primary
      },
    ],
    name: "Schedule",
    translationKey: "sidebar.schedule",
    icon: "fas fa-bus", //text-primary
    layout: "/admin",
    isPermitted: (user) => user.perms.includes("studentView"),
  },
  {
    path: "/grades",
    name: "Grades",
    translationKey: "userData.grade",
    icon: "fas fa-user-graduate", //text-primary
    component: Grades,
    layout: "/admin",
    isPermitted: (user) => user.perms.includes("studentView"),
  },
  {
    path: "/drivers",
    name: "Drivers",
    translationKey: "userData.drivers",
    icon: "fas fa-user-tie", //text-primary
    component: Drivers,
    layout: "/admin",
    isPermitted: (user) => user.perms.includes("studentView"),
  },
  {
    path: "/buses",
    name: "Buses",
    translationKey: "userData.buses",
    icon: "fas fa-bus", //text-primary
    component: Buses,
    layout: "/admin",
    isPermitted: (user) => user.perms.includes("studentView"),
  },
];

import { Loading, Message } from "element-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCurrentUser } from "src/api/users.js";
import { removeToken, setToken } from "src/utils/tokencookie.js";
import app from "./base.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // User Info request here
        const idToken = await user.getIdToken();
        setToken(idToken);
        const toCheckUser = await getCurrentUser(idToken);
        if (toCheckUser.isAdmin || toCheckUser.isStaff || toCheckUser.teacher) {
          setCurrentUser(toCheckUser);
        } else {
          // Logout the user if does not have permission
          setCurrentUser(null);
          app.auth().signOut();
          Message.error({
            message: t("msg.accessDenied"),
            customClass: "message-box-class",
          });
        }
      } else {
        setCurrentUser(null);
      }
      setPending(false);
    });

    app.auth().onIdTokenChanged(async (user) => {
      if (user) {
        //add token to cookies
        setToken(await user.getIdToken());
      } else {
        //remove from cookies
        removeToken();
      }
    });
  }, [t]);

  if (pending) {
    return (
      <div className="center-div">
        <Loading />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

import { Card, Layout } from "element-react";
import React, { useEffect, useState } from "react";
import StudentsClassList from "src/components/Tables/StudentsClassList";
import TasksTable from "src/components/Tables/TasksTable";
import "./Class.css";

export default ({ selectedClass }) => {
  const [students, setstudents] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [exams, setExams] = useState([]);
  useEffect(() => {
    // TODO: Make the request of the selected class here
    console.log("Class", selectedClass);
    const recievedStudents = [
      {
        id: 1,
        name: "Hello1",
        grade: "4th",
        phone: "anynumber",
        email: "anyemail@example.com",
        image:
          "https://cdn.pixabay.com/photo/2018/06/27/07/45/student-3500990_1280.jpg",
      },
      {
        id: 2,
        name: "Hello2",
        grade: "4th",
        phone: "anynumber",
        email: "anyemail@example.com",
        image:
          "https://cdn.pixabay.com/photo/2018/06/27/07/45/student-3500990_1280.jpg",
      },
      {
        id: 3,
        name: "Hello3",
        grade: "4th",
        phone: "anynumber",
        email: "anyemail@example.com",
        image:
          "https://cdn.pixabay.com/photo/2018/06/27/07/45/student-3500990_1280.jpg",
      },
      {
        id: 4,
        name: "Hello4",
        grade: "4th",
        phone: "anynumber",
        email: "anyemail@example.com",
        image:
          "https://cdn.pixabay.com/photo/2018/06/27/07/45/student-3500990_1280.jpg",
      },
    ];

    const recievedAssignmnentsOrExams = [
      {
        name: "Hello",
        description: "Hello",
        file: null,
        assignedDate: Date.now(),
        deadline: Date.now(),
        isOnline: undefined,
      },
      {
        name: "Hello",
        description: "Hello",
        file: null,
        assignedDate: Date.now(),
        deadline: Date.now(),
        isOnline: undefined,
      },
      {
        name: "Hello",
        description: "Hello",
        file: null,
        assignedDate: Date.now(),
        deadline: Date.now(),
        isOnline: undefined,
      },
    ];

    setstudents(recievedStudents);
    setAssignments(recievedAssignmnentsOrExams);
    setExams(recievedAssignmnentsOrExams);
  }, [selectedClass]);
  return (
    <div className="class-whole-content">
      <Layout.Row>
        <Layout.Col md="12">
          <div style={{ padding: "10px" }}>
            <h1>Assignments</h1>
            <Card className="box-card">
              <TasksTable tasks={assignments} />
            </Card>
          </div>
        </Layout.Col>

        <Layout.Col md="12">
          <div style={{ padding: "10px" }}>
            <h1>Exams</h1>
            <Card className="box-card">
              <TasksTable tasks={exams} />
            </Card>
          </div>
        </Layout.Col>
      </Layout.Row>

      <Layout.Row>
        <Layout.Col md="12">
          <div style={{ padding: "10px" }}>
            <h1>Students</h1>
            <Card className="box-card">
              <StudentsClassList students={students} />
            </Card>
          </div>
        </Layout.Col>
      </Layout.Row>
    </div>
  );
};

import axios from "./index";

export const getAllRoutes = async (query) => {
  return (await axios.get("/api/route", { params: { query } })).data;
};

export const addRoute = async (data) => {
  return (await axios.post("/api/route", data)).data;
};

export const updateRoute = async (id, data) => {
  return (await axios.patch(`/api/route/${id}`, data)).data;
};

export const deleteRoute = async (id) => {
  return (await axios.delete(`/api/route/${id}`)).data;
};

export const addRouteStation = async (data) => {
  return (await axios.post(`/api/route/station`, data)).data;
};

export const deleteRouteStation = async (id) => {
  return (await axios.delete(`/api/route/station/${id}`)).data;
};

export const exchangeRouteStations = async (id1, id2) => {
  return (await axios.patch(`/api/route/station/${id1}/${id2}`)).data;
};

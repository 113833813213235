import { Backdrop, Box, Fade, Modal, TextField } from "@material-ui/core";
import { Loading, Message, MessageBox } from "element-react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { addStation, deleteStation, getAllStations, updateStation } from "src/api/stations";
import { AuthContext } from "src/firebase/Auth";
import StationsTable from "../components/Tables/StationsTable";

export default (props) => {
  const { t } = useTranslation();
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [editingStationID, setEditingStationID] = useState(null);
  // const [mapZoom, setMapZoom] = useState(10);
  const initialFormState = {
    name: "",
    description: "",
    longitude: "",
    latitude: "",
  };
  const [formData, setFormData] = useState(initialFormState);
  const { currentUser } = useContext(AuthContext);

  const handleAddStation = async (invite) => {
    setLoading(true);
    addStation(formData)
      .then((data) => {
        setStations([...stations, data]);
        setLoading(false);
        handleCloseModal();
        Message.success("Station added successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Station couldn't be added.",
          customClass: "message-box-class",
        });
      });
  };
  const handleEditStation = async () => {
    setLoading(true);
    updateStation(editingStationID, formData)
      .then((res) => {
        console.log(res);
        setStations(
          stations.map((station) => {
            if (station.id === editingStationID) return res;
            return station;
          })
        );
        setLoading(false);
        handleCloseModal();
        Message.success("Station edited successfully.");
      })
      .catch((err) => {
        setLoading(false);
        Message.error({
          message: "Station couldn't be edited.",
          customClass: "message-box-class",
        });
      });
  };

  const handleStationDelete = async (stationID) => {
    MessageBox.confirm(
      "This will permanently delete the grade. Continue?",
      "Warning",
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        setLoading(true);
        deleteStation(stationID)
          .then((res) => {
            setStations(stations.filter((station) => station.id !== stationID));
            Message({
              type: "success",
              message: "Station deleted successfully.",
            });
          })
          .catch((error) => {
            Message({
              type: "error",
              message: "Station couldn't be deleted.",
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  const handleStationEdit = async (stationID) => {
    setEditingStationID(stationID);
    const editingStation = stations.find((station) => station.id === stationID);
    setFormData({
      name: editingStation.name ?? "",
      description: editingStation.description ?? "",
      longitude: editingStation.longitude ?? "30.0594699",
      latitude: editingStation.latitude ?? "31.1884238",
    });
    setAddVisible(true);
  };

  const handleFormChange = (e) => {
    const eName = e.target.name;
    setFormData({ ...formData, [eName]: e.target.value });
    if (e.target.required) {
      if (e.target.value !== initialFormState[eName])
        setIsWrong({ ...isWrong, [eName]: false });
    }
  };

  const handleCloseModal = async () => {
    setFormData(initialFormState);
    setIsWrong(initialWrongState);
    setEditingStationID(null);
    setAddVisible(false);
  };
  useEffect(() => {
    setLoading(true);
    getAllStations()
      .then((stations) => {
        console.log(stations);
        setStations(stations);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [currentUser]);

  const validateInput = () => {
    return (
      formData.name !== initialFormState.name &&
      formData.description !== initialFormState.description
    );
  };
  const initialWrongState = {
    name: false,
    description: false,
  };
  const [isWrong, setIsWrong] = useState(initialWrongState);
  const showInputError = async () => {
    setIsWrong({
      name: formData.name === initialFormState.name,
      description: formData.description === initialFormState.description,
    });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    bgcolor: "#FFFFFF",
    boxShadow: 24,
    borderRadius: 12,
    p: 4,
  };
  return (
    <div style={{}}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="default"
          onClick={() => {
            setAddVisible(true);
          }}
        >
          <i className="fas fa-plus" />

          {t("actions.add")}
          <i className="fas fa-map-marker-alt" />
        </Button>
      </div>

      <Modal
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={handleCloseModal}
        open={addVisible}
      >
        <Fade in={addVisible}>
          <Box sx={style}>
            <Box
              sx={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <h5 className="modal-title" id="exampleModalLabel">
                {editingStationID ? t("actions.edit") : t("actions.add")}
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseModal}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </Box>
            <Box
              sx={{
                padding: "0px 20px",
                flexDirection: "column",
                display: "flex",
                marginTop: "20px",
              }}
            >
              <TextField
                required
                error={isWrong.name}
                label={t("userData.name")}
                helperText={isWrong.name ? "Name is required." : ""}
                variant="outlined"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                style={{ marginBottom: "10px", width: "50%" }}
              />

              <TextField
                required
                error={isWrong.description}
                label={t("userData.description")}
                helperText={
                  isWrong.description ? "Description is required." : ""
                }
                variant="outlined"
                type="text"
                name="description"
                value={formData.description}
                onChange={handleFormChange}
                style={{ marginBottom: "10px", width: "50%" }}
              />
              {/* <MapPicker
                defaultLocation={{
                  lat: parseFloat(formData.latitude),
                  lng: parseFloat(formData.longitude),
                }}
                zoom={mapZoom}
                style={{ height: "200px" }}
                onChangeLocation={(lat, lng) => {
                  setFormData({
                    ...formData,
                    latitude: lat.toString(),
                    longitude: lng.toString(),
                  });
                }}
                onChangeZoom={(newZoom) => {
                  setMapZoom(newZoom);
                }}
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              /> */}
            </Box>
            <Box
              sx={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                display: "flex",
                marginTop: "20px",
              }}
            >
              <Button color="secondary" onClick={handleCloseModal}>
                {t("actions.cancel")}
              </Button>

              {editingStationID ? (
                <Button
                  color={validateInput() ? "success" : "secondary"}
                  onClick={validateInput() ? handleEditStation : showInputError}
                >
                  {t("actions.ok")}
                </Button>
              ) : (
                <Button
                  color={validateInput() ? "success" : "secondary"}
                  onClick={validateInput() ? handleAddStation : showInputError}
                >
                  {t("actions.add")}
                </Button>
              )}
            </Box>
          </Box>
        </Fade>
      </Modal>

      <div className="list-of-stations">
        <Loading loading={loading}>
          <StationsTable
            stations={stations}
            onStationDelete={handleStationDelete}
            onStationEdit={handleStationEdit}
          />
        </Loading>
      </div>
    </div>
  );
};
